import { memo } from 'react'

import { Spacings } from '@contracts/enums/Spacings'

import Texts from '@pure/assets/PayTexts'
import { getFigmaTextFromVariable } from '@pure/libs/TextRepository'
import { formatText } from '@pure/libs/TextRepositoryHelper'

import { Variables } from '@pay/libs/Variables'

import Box from './Box'
import FigmaText from './FigmaText'

type Props = {
  isOffense?: boolean
  item: any
  type: string
}

const PricingTable = ({ isOffense, item, type }: Props) => (
  <>
    {isOffense &&
      item?.items.map((offense, index) => (
        <Box key={index} fullWidth direction="row" align="center" justify="space-between">
          <Box>
            <FigmaText
              textKey={Texts.textActiveSessionParkingInformationInformationScreenMainInfo}
              text={
                type === 'relative'
                  ? offense.intervalMinutes === 0 || offense.intervalMinutes === undefined
                    ? index === 0
                      ? getFigmaTextFromVariable(Variables['VariableID:4496:12919'])
                      : getFigmaTextFromVariable(Variables['VariableID:4479:20051'])
                    : index === 0
                      ? formatText(
                          getFigmaTextFromVariable(Variables['VariableID:4479:20049']),
                          [offense?.intervalMinutes],
                          { splitCharacter: 'xxx' }
                        )
                      : formatText(
                          getFigmaTextFromVariable(Variables['VariableID:4479:20050']),
                          [offense?.intervalMinutes],
                          { splitCharacter: 'xxx' }
                        )
                  : formatText(
                      getFigmaTextFromVariable(Variables['VariableID:4479:20486']),
                      [offense?.fromTime, offense?.toTime],
                      { splitCharacter: 'HH:mm' }
                    )
              }
            />
          </Box>
          <Box>
            <Box fullWidth direction="row" align="center" gap={Spacings.xxs}>
              <FigmaText
                textKey={Texts.textActiveSessionParkingInformationInformationCssFeeValue}
                text={(offense?.perHourPrice * 1.25).toString()}
              />
              <FigmaText
                textKey={Texts.textActiveSessionParkingInformationInformationScreenMainInfo}
                text={getFigmaTextFromVariable(Variables['VariableID:649:7443'])}
              />
              <FigmaText
                textKey={Texts.textActiveSessionParkingInformationInformationScreenMainInfo}
                text={getFigmaTextFromVariable(Variables['VariableID:4479:20054'])}
              />
            </Box>
          </Box>
        </Box>
      ))}
    {!isOffense &&
      item?.map((parking, index) => (
        <Box key={index} fullWidth direction="row" align="center" justify="space-between">
          <Box>
            <FigmaText
              textKey={Texts.textActiveSessionParkingInformationInformationScreenMainInfo}
              text={
                type === 'relative'
                  ? parking.intervalMinutes === 0
                    ? index === 0
                      ? getFigmaTextFromVariable(Variables['VariableID:4496:12919'])
                      : getFigmaTextFromVariable(Variables['VariableID:4479:20051'])
                    : index === 0
                      ? formatText(
                          getFigmaTextFromVariable(Variables['VariableID:4479:20049']),
                          [parking?.intervalMinutes],
                          { splitCharacter: 'xxx' }
                        )
                      : formatText(
                          getFigmaTextFromVariable(Variables['VariableID:4479:20050']),
                          [parking?.intervalMinutes],
                          { splitCharacter: 'xxx' }
                        )
                  : formatText(
                      getFigmaTextFromVariable(Variables['VariableID:4479:20486']),
                      [parking?.fromTime, parking?.toTime],
                      { splitCharacter: 'HH:mm' }
                    )
              }
            />
          </Box>
          <Box>
            <Box fullWidth direction="row" align="center" gap={Spacings.xxs}>
              <FigmaText
                textKey={Texts.textActiveSessionParkingInformationInformationCssFeeValue}
                text={(parking?.perHourPrice * 1.25).toString()}
              />
              <FigmaText
                textKey={Texts.textActiveSessionParkingInformationInformationScreenMainInfo}
                text={getFigmaTextFromVariable(Variables['VariableID:649:7443'])}
              />
              <FigmaText
                textKey={Texts.textActiveSessionParkingInformationInformationScreenMainInfo}
                text={getFigmaTextFromVariable(Variables['VariableID:4479:20054'])}
              />
            </Box>
          </Box>
        </Box>
      ))}
  </>
)

export default memo(PricingTable)
