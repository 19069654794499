import { AuthorizedRequest } from '@contracts/types/AuthorizedRequest'
import { CloudFunctions, GetActiveSessionPublicForPlateRequest } from '@contracts/types/CloudFunctionTypes'
import {
  ConvertToFreeSessionRequest,
  GetGuestParkingPermitsRequest,
  GetGuestParkingPermitsResponse,
  GetLinkToGuestParkingFeatureRequest,
  GetLinkToGuestParkingFeatureResponse,
  GetLinkToGuestParkingRequest,
  GuestParkingConversionRequestPayload,
  HandleGuestParkingRequest
} from '@contracts/types/FunctionsGuestParkingTypes'
import { Session } from '@contracts/types/Session'

import { callFunction } from '@web-js/libs/CloudFunctionsApiHandlerHelper'

import { functions } from './FirebaseOptions'

export const getLinkToGuestParkingFeauture = (
  data: GetLinkToGuestParkingFeatureRequest
): Promise<GetLinkToGuestParkingFeatureResponse> =>
  callFunction({
    cloudfunction: CloudFunctions.getLinkToGuestParkingFeauture,
    functions,
    data
  }) as Promise<GetLinkToGuestParkingFeatureResponse>

export const getLinkToGuestParking = (
  data: GetLinkToGuestParkingRequest
): Promise<GetLinkToGuestParkingFeatureResponse> =>
  callFunction({
    cloudfunction: CloudFunctions.getLinkToGuestParking,
    functions,
    data
  }) as Promise<GetLinkToGuestParkingFeatureResponse>

export const getGuestParkingSitePermits = (
  data: AuthorizedRequest<GetGuestParkingPermitsRequest>
): Promise<GetGuestParkingPermitsResponse> =>
  callFunction({
    cloudfunction: CloudFunctions.getGuestParkingSitePermits,
    functions,
    data
  }) as Promise<GetGuestParkingPermitsResponse>

export const getActiveSessionForPlate = (data: GetActiveSessionPublicForPlateRequest): Promise<Session | undefined> =>
  callFunction({ functions, cloudfunction: CloudFunctions.getActiveSessionForPlate, data }) as Promise<
    Session | undefined
  >

export const enqueueGuestParkingRequest = (data: GuestParkingConversionRequestPayload): Promise<Session> =>
  callFunction({ functions, cloudfunction: CloudFunctions.enqueueGuestParkingRequest, data }) as Promise<any>

export const convertToFreeSession = (data: ConvertToFreeSessionRequest): Promise<Session> =>
  callFunction({ functions, cloudfunction: CloudFunctions.convertToFreeSession, data }) as Promise<Session>

export const handleGuestParking = (data: HandleGuestParkingRequest): Promise<unknown> =>
  callFunction({ functions, cloudfunction: CloudFunctions.handleGuestParking, data }) as Promise<unknown>
