import MuiBox from '@mui/material/Box'
import { useEffect, useId } from 'react'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { GuestParkingFormValues } from '@contracts/types/GuestParkingSession'

import { Spacings } from '@guest-parking/enums/Spacings'
import { useForm } from '@guest-parking/hooks/useForm'
import { useFreeGuestParkingTokenPayload } from '@guest-parking/hooks/useFreeGuestParkingToken'
import { GuestParkingViewProps, normaliseRegistrationNumber } from '@guest-parking/libs/GuestParkingHelper'
import { useSite } from '@guest-parking/libs/ReactQueryHooks'
import { getFigmaText, getFigmaTextFromVariable } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'
import { PhoneRegExp, RequiredTextValidation } from '@guest-parking/libs/ValidationHelper'
import { Variables } from '@guest-parking/libs/Variables'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { GuestParkingFormInfoBox } from './GuestParkingFormInfoBox'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import { HomeErrorMessage } from './HomeErrorMessage'
import { Layout } from './Layout'
import TextFieldForm from './TextFieldForm'

interface GuestParkingFormValuesWithConfirmation extends GuestParkingFormValues {
  confirmRegistrationNumber: string
}

const FullyValidatedSiteIds = ['7988']

const PartialValidationSchema = Yup.object().shape({
  registrationNumber: RequiredTextValidation(getFigmaText(Texts.textRegistrationNumberRequiredError)).transform(
    normaliseRegistrationNumber
  ),
  confirmRegistrationNumber: Yup.string()
    .required(getFigmaText(Texts.textConfirmRegistrationNumberLabelHelperText))
    .transform(normaliseRegistrationNumber)
    .oneOf([Yup.ref('registrationNumber')], getFigmaText(Texts.textConfirmRegistrationNumberLabelHelperText))
})

const FullValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().matches(PhoneRegExp, 'Phone Number is NOT valid').required('Phone Number is required'),
  name: RequiredTextValidation('Enter Name'),
  companyName: Yup.string()
})

const GuestParkingForm = (props: GuestParkingViewProps) => {
  const { guestParkingState } = props
  const { session: drifterSession } = guestParkingState.data
  const payload = useFreeGuestParkingTokenPayload(guestParkingState.data.freeGuestParkingToken)
  const { data: site } = useSite(payload?.siteId ?? drifterSession?.parkingSession?.siteId ?? '')

  const hasFullForm = Boolean(site?.id && FullyValidatedSiteIds.includes(site.id))
  const ValidationSchema = hasFullForm ? PartialValidationSchema.concat(FullValidationSchema) : PartialValidationSchema

  const formProps = useForm<GuestParkingFormValuesWithConfirmation>(ValidationSchema, {
    defaultValues: {
      name: '',
      companyName: '',
      phoneNumber: '',
      registrationNumber: '',
      confirmRegistrationNumber: ''
    }
  })

  const nameFieldId = useId()
  const companyNameFieldId = useId()
  const phoneNumberFieldId = useId()
  const registrationNumberFieldId = useId()
  const confirmRegistrationNumberFieldId = useId()

  useEffect(() => {
    if (guestParkingState) {
      formProps.setValue('phoneNumber', guestParkingState.data.phoneNumber)
      formProps.setValue('name', guestParkingState.data.name)
    }
  }, [guestParkingState])

  const sessionSiteId = guestParkingState.data.session?.parkingSession?.siteId

  const onSubmit = (values: GuestParkingFormValuesWithConfirmation) => {
    if (props.isLoading || formProps.formState.isSubmitting) return

    const { confirmRegistrationNumber: _, ...valuesWithoutConfirmation } = values

    return props.onPressContinue({
      ...props.guestParkingState,
      data: {
        ...guestParkingState.data,
        ...valuesWithoutConfirmation,
        siteId: guestParkingState.data.siteId || sessionSiteId
      }
    })
  }

  return (
    <Layout>
      <Box fullWidth left right spacing={Spacings.xl}>
        <Box fullWidth align="center">
          <GuestParkingSiteLogo site={site} />
        </Box>
        <Box fullWidth align="center" top bottom spacing={Spacings.l}>
          <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingQrLandingHeader} />
        </Box>
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          {hasFullForm && (
            <Box fullWidth bottom spacing={Spacings.l}>
              <label htmlFor={nameFieldId}>
                <FigmaText
                  textKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
                  text={getFigmaTextFromVariable(Variables['VariableID:1274:12246'])}
                />
              </label>
              <Controller
                control={formProps.control}
                name="name"
                render={({ field, fieldState }) => (
                  <TextFieldForm
                    {...field}
                    id={nameFieldId}
                    value={field.value}
                    fullWidth
                    fieldState={fieldState}
                    autoFocus
                    placeholder="Anders Andersson"
                  />
                )}
              />
            </Box>
          )}

          {hasFullForm && (
            <Box fullWidth bottom spacing={Spacings.l}>
              <label htmlFor={companyNameFieldId}>
                <FigmaText
                  textKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
                  text={getFigmaTextFromVariable(Variables['VariableID:1274:12247'])}
                />
              </label>
              <Controller
                control={formProps.control}
                name="companyName"
                render={({ field, fieldState }) => (
                  <TextFieldForm
                    {...field}
                    id={companyNameFieldId}
                    value={field.value}
                    fullWidth
                    fieldState={fieldState}
                    placeholder="Company Name"
                  />
                )}
              />
            </Box>
          )}

          {hasFullForm && !guestParkingState?.data?.phoneNumber && (
            <Box fullWidth bottom spacing={Spacings.l}>
              <label htmlFor={phoneNumberFieldId}>
                <FigmaText
                  textKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
                  text={getFigmaTextFromVariable(Variables['VariableID:1274:12248'])}
                />
              </label>
              <Controller
                control={formProps.control}
                name="phoneNumber"
                render={({ field, fieldState }) => (
                  <TextFieldForm
                    {...field}
                    id={phoneNumberFieldId}
                    value={field.value}
                    fullWidth
                    fieldState={fieldState}
                    placeholder="07-"
                  />
                )}
              />
            </Box>
          )}

          <MuiBox sx={{ mb: 3 }}>
            <MuiBox component={'label'} htmlFor={registrationNumberFieldId} sx={{ mb: 1, display: 'block' }}>
              <FigmaText textKey={Texts.textRegistrationNumberLabel} />
            </MuiBox>

            <Controller
              control={formProps.control}
              name="registrationNumber"
              render={({ field, fieldState }) => (
                <TextFieldForm
                  {...field}
                  id={registrationNumberFieldId}
                  value={field.value.toUpperCase()}
                  fullWidth
                  fieldState={fieldState}
                  placeholder="ABC 123"
                />
              )}
            />
            {formProps.formState.isSubmitSuccessful && !drifterSession && (
              <Box top spacing={Spacings.xs}>
                <HomeErrorMessage
                  textKey={Texts.textPaymentsPaymentSwishNumberErrorNotConnected}
                  text={getFigmaTextFromVariable(Variables['VariableID:1274:12256'])}
                />
              </Box>
            )}
          </MuiBox>

          <Box fullWidth bottom spacing={Spacings.l}>
            <MuiBox component={'label'} htmlFor={confirmRegistrationNumberFieldId} sx={{ mb: 1, display: 'block' }}>
              <FigmaText textKey={Texts.textConfirmRegistrationNumberLabel} />
            </MuiBox>

            <Controller
              control={formProps.control}
              name="confirmRegistrationNumber"
              render={({ field, fieldState }) => (
                <TextFieldForm
                  {...field}
                  id={confirmRegistrationNumberFieldId}
                  value={field.value.toUpperCase()}
                  fullWidth
                  fieldState={fieldState}
                  helperText={getFigmaText(Texts.textConfirmRegistrationNumberLabelHelperText)}
                  placeholder="ABC 123"
                />
              )}
            />
          </Box>
          <Button
            fullWidth
            text={getFigmaTextFromVariable(Variables['VariableID:362:6744'])}
            type="submit"
            loading={props.isLoading}
          />
        </form>
        <Box fullWidth top bottom spacing={Spacings.xxl}>
          <GuestParkingFormInfoBox />
        </Box>
      </Box>
    </Layout>
  )
}

export default GuestParkingForm
