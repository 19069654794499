import { ConfigType } from '@contracts/types/ConfigType'

type Environment = 'local' | 'prod'

const env: Environment = process.env.NODE_ENV === 'production' ? 'prod' : 'local'

const defaultConfig = {
  enableSlack: true,
  enableSentry: true
}

const configs: { [property in Environment]: ConfigType } = {
  local: {
    ...defaultConfig,
    enableSlack: env !== 'local',
    enableSentry: false
  },
  prod: {
    ...defaultConfig
  }
}

export const config: ConfigType = configs[env] || configs.prod
