import dayjs from 'dayjs'

import { GetParkingSessionOffenseEventData } from '@contracts/types/GetParkingSessionData'
import { OffenseType } from '@contracts/types/OffenseType'
import { OffenseTypeItem } from '@contracts/types/OffenseType'
import { SessionEventName } from '@contracts/types/Session'
import { Vehicle } from '@contracts/types/Vehicle'

import { hasValidPermit } from '@pure/libs/permit/hasValidPermit'
import { VehicleEUCategory } from '@pure/swagger/VehicleEUCategory'

export const OffenseTypes: {
  [property in OffenseType]: OffenseTypeItem
} = {
  [OffenseType.disabled_slot]: {
    featureKey: 'enableNoPermitInDisabledSlotOffenseFee',
    maxAmountKey: 'noPermitInDisabledSlotOffenseMaxFee',
    getPartialEvent: ({ slot, user }) => (slot?.type === 'handicap' && !user?.disabledUser ? {} : undefined)
  },
  [OffenseType.marked_slot]: {
    featureKey: 'enableMarkedSlotOffenseFee',
    maxAmountKey: 'markedSlotOffenseMaxFee',
    getPartialEvent: ({ slot }) => (slot?.type === 'marked' ? {} : undefined)
  },
  [OffenseType.vehicle_position]: {
    featureKey: 'enableFossilCarInEVSlotOffenseFee',
    maxAmountKey: 'fossilCarInEVSlotOffenseMaxFee'
  },
  [OffenseType.quickstop_overstay]: {
    featureKey: 'enableQuickStopOffenseFee',
    maxAmountKey: 'quickStopOffenseMaxFee',
    getPartialEvent: (data) => {
      const { slot, pricing, currentCameraEvent, now, cameraEvents, vehicle } = data
      if (slot?.type !== 'quick_stop') return
      const pricingItem = pricing?.offense?.find((offense) => offense.offenseType === OffenseType.quickstop_overstay)

      // TODO WRITE TEST; should return false if no pricingItem is configured
      if (!pricingItem) return

      const overstayOffenseFeeVehicleTypeWhiteList = pricing?.overstayOffenseFeeVehicleTypeWhiteList || []

      // TODO WRITE tEST, should return false if vehicle is in the white list

      if (
        overstayOffenseFeeVehicleTypeWhiteList.includes(
          vehicle?.metadata?.valitiveVehicle?.detail?.vehicleCategory as VehicleEUCategory
        )
      )
        return

      const { freeDurationMinutesForQuickStopOffense = 0 } = pricingItem

      const firstCameraEvent = cameraEvents.find((event) => event.place_code === currentCameraEvent?.place_code)

      const start = dayjs(firstCameraEvent?.record_time.toDate())

      const diff = dayjs(now).diff(start, 'minutes')

      switch (true) {
        case diff < 0:
          return undefined
        case 0 <= diff && diff < freeDurationMinutesForQuickStopOffense:
          return {
            name: SessionEventName.PARKING_FREE,
            type: 'free'
          }
        case diff >= freeDurationMinutesForQuickStopOffense:
          return {
            name: SessionEventName.PARKING_OFFENSE,
            type: 'offense'
          }
        default:
          return undefined
      }
    }
  },
  [OffenseType.charge_slot_offense]: {
    featureKey: 'enableFossilCarInEVSlotOffenseFee',
    maxAmountKey: 'fossilCarInEVSlotOffenseMaxFee',
    getPartialEvent: (data: GetParkingSessionOffenseEventData) => {
      const { slot, vehicle, permits = [], site, currentCameraEvent, now } = data
      // TODO WRITE TEST no offense if slot does not have a type
      if (!slot?.type) return
      if (slot.type !== 'ev') return

      const isEv = isEVVehicle(vehicle)

      const hasPermit = currentCameraEvent && vehicle && hasValidPermit(data)

      // TODO WRITE TEST should not assign an offense if user has permit on bergsala site
      if (!isEv && hasPermit && site.enableOverrideChargeSlotOffenseWithPermit) return

      // TODO WRITE TEST should return true if slot is marked as EV and vehicle is not EV
      if (!isEv) return {}

      return
    }
  }
}

export enum OffenseTypeId {
  disabled_slot = 1,
  marked_slot = 2,
  vehicle_position = 3,
  quickstop_slot = 4,
  charge_slot_offense = 5
}

export const OffenseTypeIdByMode: { [mode: string]: OffenseTypeId } = {
  disabled_slot: OffenseTypeId.disabled_slot,
  marked_slot: OffenseTypeId.marked_slot,
  vehicle_position: OffenseTypeId.vehicle_position,
  quickstop_overstay: OffenseTypeId.quickstop_slot,
  charge_slot_offense: OffenseTypeId.charge_slot_offense
}

export function isEVVehicle(vehicle: Vehicle) {
  return vehicle?.metadata?.valitiveVehicle?.technicalData?.fuelCodes?.includes('EL_FUEL_CODE')
}
