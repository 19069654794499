import qs from 'query-string'

export type PageSignInRoamerQueryParams = { id: string }

export type PaymentCallbackQueryParams = {
  hash: string
  success?: string
}

export type HomeLandingQueryParams = {
  parkId?: string
}

export type SwishPaymentRequestQueryParams = {
  token?: string
}

export type ReceiptQueryParams = {
  sessionId?: string
  success?: string
  hash?: string
}

export type FortnoxOauthRedirectURIQueryParams = {
  code?: string
  state?: string
  error?: string
  error_description?: string
}

export type AllAvailableQueryParams = Partial<PaymentCallbackQueryParams> &
  Partial<SwishPaymentRequestQueryParams> &
  Partial<ReceiptQueryParams> &
  Partial<FortnoxOauthRedirectURIQueryParams>

export default function useQueryParams<T>(search: string = window.location.search): T {
  // eslint-disable-next-line prettier/prettier
  const queryParams = (parse(search) as unknown) as T
  return queryParams as T
}

export const getQueryParams = useQueryParams

export const parse = (text: string) => {
  text = text.split('?')[1]
  return qs.parse(decodeURIComponent(text))
}

export const parseEmailQueryParam = (url: string) => {
  const suffix = url.split('email=')[1]
  if (!suffix) return ''
  return suffix.split('&')[0]
}
