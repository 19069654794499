import Box from '@guest-parking/components/Box'
import Button from '@guest-parking/components/Button'
import { FigmaTextWithStyle } from '@guest-parking/components/FigmaTextWithStyle'
import { Spacings } from '@guest-parking/enums/Spacings'
import { useLogout } from '@guest-parking/hooks/useLogout'
import { getFigmaText } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'

export const GuestParkingNotAuthorizedScreen: React.FC<{ enableLogoutButton?: boolean }> = () => {
  const logout = useLogout()
  return (
    <Box fullWidth fullHeight align="center" justify="center">
      <Box top spacing={Spacings.xxxl}>
        <FigmaTextWithStyle type={'headingsH4'} text="Not Authorized" />
      </Box>
      <Box top>
        <FigmaTextWithStyle
          type={'body1Regular'}
          text={getFigmaText(Texts.textNotificationsNotificationGuestParkingErrorSentCode)}
        />
      </Box>
      <Box top>
        <Button
          fullWidth
          onClick={logout}
          textKey={Texts.textMenuUserMenuUserMenuLogout}
          verticalSpacing={Spacings.s}
          buttonType="secondary"
        />
      </Box>
    </Box>
  )
}
