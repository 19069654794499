import { ROAMER_APP_STORE_URL, ROAMER_PLAY_STORE_URL } from '@web-js/libs/Consts'

import { Spacings } from '@guest-parking/enums/Spacings'
import Images from '@guest-parking/libs/Images'

import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export function AppStoreButtons({ width = Images.headerAppStore.width }: { width?: number }) {
  return (
    <Box>
      <Box link onClick={() => window.open(ROAMER_APP_STORE_URL, '_blank')}>
        <FigmaImageContainer imageKey={Images.homeLandingAppStore} width={width} />
      </Box>
      <Box
        top
        spacing={Spacings.xs}
        onClick={() => window.open(ROAMER_PLAY_STORE_URL, '_blank')}
        width={`${width}px`}
        pointer
      >
        <img src="https://lh3.googleusercontent.com/q1k2l5CwMV31JdDXcpN4Ey7O43PxnjAuZBTmcHEwQxVuv_2wCE2gAAQMWxwNUC2FYEOnYgFPOpw6kmHJWuEGeIBLTj9CuxcOEeU8UXyzWJq4NJM3lg=s0" />
      </Box>
    </Box>
  )
}
