import dayjs from 'dayjs'

import { ServerFirebaseUser } from '@pure/emu/ServerTypes'
import { BaseObject } from '@pure/emu/types'

type MapFunction = (item: any) => any

export const toUpdatedObject = <T extends BaseObject>(object: T, u?: ServerFirebaseUser): T => ({
  ...object,
  updatedAt: dayjs().format(),
  updatedBy: u?.uid || 'admin'
})

export const mapQueryResponse = (response: any, mapFn?: MapFunction) =>
  response.docs ? getCollectionData(response, mapFn) : getDocData(response, mapFn)

// Get array of doc data from collection
export const getCollectionData = (collection, mapFn?: MapFunction) =>
  collection.docs.map((doc) => getDocData(doc, mapFn))

// Get doc data and merge doc.id
function getDocData(doc, mapFn?: MapFunction) {
  if (!doc.exists()) return null
  const data = { id: doc.id, ...doc.data() }
  return mapFn ? mapFn(data) : data
}
