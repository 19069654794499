import invariant from 'invariant'

import { SlackWebhooks } from '@contracts/enums/SlackWebhooks'
import { ConvertToFreeSessionRequest } from '@contracts/types/FunctionsGuestParkingTypes'
import { GuestParkingSession } from '@contracts/types/GuestParkingSession'

import { slack } from '@web-js/libs/SlackHelperClient'

import { captureException } from '@guest-parking/Sentry'

import {
  convertToFreeSession,
  enqueueGuestParkingRequest,
  getActiveSessionForPlate,
  handleGuestParking
} from './CloudFunctionsApiHandler'
import { upsertGuestParkingSession } from './DBApiHandler'
import { showGenericErrorToast } from './ErrorHelper'

export enum GuestParkingSteps {
  GUEST_PARKING_FORM = 'GUEST_PARKING_FORM',
  GUEST_PARKING_CONDITIONS = 'GUEST_PARKING_CONDITIONS',
  GUEST_PARKING_INFORMATION = 'GUEST_PARKING_INFORMATION'
}

export type GuestParkingStateData = GuestParkingSession

export type GuestParkingState = {
  data: GuestParkingStateData
  step: GuestParkingSteps
  hasEnqueuedGuestParkingRequest?: boolean
}

export type GuestParkingViewProps = {
  isLoading: boolean
  guestParkingState: GuestParkingState
  onPressContinue: (state: GuestParkingState) => Promise<unknown>
}

const handleException = (e: any, guestParkingState: GuestParkingState, step: GuestParkingSteps) => {
  console.error(e)
  captureException(e, (scope) => {
    scope.addBreadcrumb({ message: `guestParkingState.data`, data: guestParkingState.data })
    scope.addBreadcrumb({ message: `guestParkingState.data.session`, data: guestParkingState.data.session })
    scope.addBreadcrumb({ message: `guestParkingState.data.session`, data: guestParkingState.data.session })

    scope.setTransactionName(`GuestParkingHelper--${step}`)

    return scope
  })

  showGenericErrorToast(e)
}

export const DEFAULT_GUEST_PARKING_STATE: GuestParkingState = {
  data: {
    session: undefined,
    siteId: '',
    name: '',
    companyName: '',
    phoneNumber: '',
    registrationNumber: '',
    permitId: '',
    uid: ''
  } as GuestParkingStateData,
  step: GuestParkingSteps.GUEST_PARKING_FORM
}

export const onPressContinue = async (guestParkingState: GuestParkingState): Promise<GuestParkingState> => {
  switch (guestParkingState.step) {
    case GuestParkingSteps.GUEST_PARKING_FORM: {
      try {
        const drifterSession = await getActiveSessionForPlate({ plate: guestParkingState.data.registrationNumber })

        const guestParkingSiteId = guestParkingState.data.siteId
        const isParkedAtDifferentSite =
          drifterSession && guestParkingSiteId && guestParkingSiteId !== drifterSession.parkingSession?.siteId
        const freeGuestParkingToken = guestParkingState.data.freeGuestParkingToken

        invariant(guestParkingSiteId, 'guestParkingSiteId must be defined')
        invariant(freeGuestParkingToken, 'freeGuestParkingToken must be defined')

        await slack(
          `GuestParking web: guest parking form submitted for plate ${guestParkingState.data.registrationNumber} at site ${guestParkingSiteId}`,
          SlackWebhooks.OPS_HIKER_GUEST_PARKING
        )

        if (!drifterSession) {
          await slack(
            `GuestParking web: no session found, will do enqueueGuestParkingRequest for plate ${guestParkingState.data.registrationNumber} at site ${guestParkingSiteId}`,
            SlackWebhooks.OPS_HIKER_GUEST_PARKING
          )
          await enqueueGuestParkingRequest({
            plate: guestParkingState.data.registrationNumber,
            siteId: guestParkingSiteId,
            freeGuestParkingToken
          })

          return {
            ...guestParkingState,
            step: GuestParkingSteps.GUEST_PARKING_CONDITIONS,
            hasEnqueuedGuestParkingRequest: true
          }
        }

        if (isParkedAtDifferentSite) {
          await slack(
            `GuestParking web: plate ${guestParkingState.data.registrationNumber} is parked at ${guestParkingSiteId} which is different site than ${drifterSession.parkingSession?.siteId}`,
            SlackWebhooks.OPS_HIKER_DEBUG
          )
          return { ...guestParkingState, step: GuestParkingSteps.GUEST_PARKING_FORM }
        }

        const retrievedSessionsiteId = drifterSession.parkingSession?.siteId
        const guestParkingStateSessionSiteId = guestParkingState.data.session?.parkingSession?.siteId

        await slack(
          `GuestParking web: active parking session found ${drifterSession.id} for plate ${guestParkingState.data.registrationNumber}; will convert into guest free session`,
          SlackWebhooks.OPS_HIKER_GUEST_PARKING
        )

        await upsertGuestParkingSession({
          ...guestParkingState.data,
          siteId: guestParkingState.data.siteId || retrievedSessionsiteId || guestParkingStateSessionSiteId
        })

        return {
          ...{ ...guestParkingState, data: { ...guestParkingState.data, session: drifterSession } },
          step: GuestParkingSteps.GUEST_PARKING_CONDITIONS
        }
      } catch (e) {
        await slack(`GuestParking web: error in onPressContinue, error: ${e}`, SlackWebhooks.OPS_HIKER_GUEST_PARKING)
        handleException(e, guestParkingState, GuestParkingSteps.GUEST_PARKING_FORM)

        return guestParkingState
      }
    }

    case GuestParkingSteps.GUEST_PARKING_CONDITIONS: {
      let session = guestParkingState.data.session

      if (guestParkingState.hasEnqueuedGuestParkingRequest) {
        return {
          ...guestParkingState,
          step: GuestParkingSteps.GUEST_PARKING_INFORMATION
        }
      }

      const sessionId = guestParkingState.data.session?.id?.toString()
      const freeGuestParkingToken = guestParkingState.data.freeGuestParkingToken
      invariant(freeGuestParkingToken, 'freeGuestParkingToken must be defined')

      try {
        if (guestParkingState.data.permitId) {
          const {
            data: { uid = '', registrationNumber, freeGuestParkingToken = '' }
          } = guestParkingState

          /**
           * This feature is not live yet, and no one has permitId's yet.
           * TODO: Might need to return session as well like convertToFreeSession does
           */
          await handleGuestParking({ uid, plates: [registrationNumber], freeGuestParkingToken })
        } else {
          session = await convertToFreeSession({
            sessionId,
            freeGuestParkingToken
          } as ConvertToFreeSessionRequest)
        }
      } catch (e: any) {
        handleException(e, guestParkingState, GuestParkingSteps.GUEST_PARKING_CONDITIONS)

        return { ...guestParkingState, step: GuestParkingSteps.GUEST_PARKING_CONDITIONS }
      }

      return {
        ...guestParkingState,
        step: GuestParkingSteps.GUEST_PARKING_INFORMATION,
        data: { ...guestParkingState.data, session }
      }
    }
    default:
      throw new Error(`Unknown step: ${guestParkingState.step}`)
  }
}

export function normaliseRegistrationNumber(registrationNumber: string) {
  return registrationNumber.trim().replace(/\s/g, '').toUpperCase()
}
