import styled from '@emotion/styled'

import { Colors } from '@pure/assets/Colors'

import { useIsDesktop } from '@guest-parking/hooks/useIsMobile'

import Box from './Box'
import { LAYOUT_CHILDREN_MAX_WIDTH } from './HardcodedSizes'
import { LayoutHeaderApp, LayoutHeaderDesktop } from './LayoutHeaderApp'

export type LayoutProps = {
  onClickHeaderLogo?: () => unknown
  children
}

export const Layout: React.FC<LayoutProps> = ({ onClickHeaderLogo, children }) => {
  const isDesktop = useIsDesktop()
  const LauyoutHeader = isDesktop ? LayoutHeaderDesktop : LayoutHeaderApp
  return (
    <Container fullWidth style={{ minHeight: '100vh' }}>
      <Box fullWidth align="center">
        <LauyoutHeader onClickHeaderLogo={onClickHeaderLogo} />
        <Box fullWidth style={{ maxWidth: isDesktop ? LAYOUT_CHILDREN_MAX_WIDTH : undefined }}>
          {children}
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
`
