import invariant from 'invariant'

import { GetParkingSessionPermitEventData } from '@contracts/types/GetParkingSessionData'
import { GetPermitResultForSession } from '@contracts/types/GetPermitResultForSession'

export function isValidPermitSlot({
  permit,
  site,
  currentCameraEvent
}: GetParkingSessionPermitEventData): GetPermitResultForSession {
  // TODO WRITE TEST, "should not allow include and exclude slot place codes"
  invariant(
    !(permit.includeSlotPlaceCodes && permit.excludeSlotPlaceCodes),
    'Cant have both include and exclude slot place codes for permit: %s',
    permit.id
  )

  const { includeSlotPlaceCodes, excludeSlotPlaceCodes, segmentIds } = permit

  if (!currentCameraEvent) return { result: true, messages: [], permit }
  const { place_code: placeCode } = currentCameraEvent

  const segment = site.segments.find((segment) => segment.slots.find((s) => s.placeCode === placeCode))

  const slot = segment?.slots.find((s) => s.placeCode === placeCode)

  // TODO WRITE TEST, "should return true if we dont know slot"
  if (!slot) return { result: true, messages: [], permit }

  // TODO WRITE TEST, should check if the vehicle actially is an evvehicle and slot is marked as ev

  switch (true) {
    // TODO WRITE TEST, should allow included cars
    case includeSlotPlaceCodes && includeSlotPlaceCodes.includes(slot.placeCode || ''):
      return {
        result: true,
        messages: [`Placecode: ${slot.placeCode} included in permit: ${permit.id} `],
        permit
      }

    // TODO WRITE TEST, should dissallow excluded cars
    case excludeSlotPlaceCodes && excludeSlotPlaceCodes.includes(slot.placeCode || ''):
      return {
        result: false,
        messages: [`Placecode: ${slot.placeCode} excluded in permit: ${permit.id} `],
        permit
      }

    // TODO WRITE TEST, should allow card in segment
    case segmentIds && !segmentIds.includes(segment?.id || ''):
      return {
        result: false,
        messages: [`SegmentId: ${segment?.id} not included in permit: ${permit.id} `],
        permit
      }

    default:
      return { result: true, messages: [], permit }
  }
}
