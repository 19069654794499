import { Spacings } from '@guest-parking/enums/Spacings'
import Images from '@guest-parking/libs/Images'
import Texts from '@guest-parking/libs/Texts'

import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export default function ErrorBoundaryView() {
  return (
    <Box fullWidth fullPadding align="center" spacing={Spacings.xxl} fullHeight>
      <Box>
        <FigmaImageContainer imageKey={Images.receiptDrifterLogo} />
      </Box>
      <Box top>
        <FigmaText textKey={TextKeys.errorMaintenanceTitle} />
      </Box>
      <Box top>
        <FigmaText textKey={TextKeys.errorMaintenanceDescription} />
      </Box>
    </Box>
  )
}

export const TextKeys = {
  errorMaintenanceTitle: {
    name: 'errorMaintenanceTitle',
    characters: '',
    texts: {
      swe: Texts.textActiveSessionGuestParkingGuestParkingInvalidToken.texts.swe,
      eng: Texts.textActiveSessionGuestParkingGuestParkingInvalidToken.texts.eng
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '36px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  errorMaintenanceDescription: {
    name: 'errorMaintenanceDescription',
    characters: '',
    texts: {
      swe: Texts.textActiveSessionGuestParkingGuestParkingInvalidTokenContactSupport.texts.swe,
      eng: Texts.textActiveSessionGuestParkingGuestParkingInvalidTokenContactSupport.texts.eng
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(145, 143, 153, 1)'
    },
    pageName: 'Payment Flows QR'
  }
}
