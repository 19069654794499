import Texts from '@pay/assets/texts.json'

export const DeprecatedTexts = {
  textActiveSessionActiveSessionActiveSessionH1Park: {
    characters: 'Park',
    name: 'textActiveSessionActiveSessionActiveSessionH1Park',
    texts: {
      swe: 'Parkera',
      eng: 'Park'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '-0.36px',
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Mobile App'
  },
  textActiveSessionActiveSessionActiveSessionH1ParkCharge: {
    characters: 'Park & Charge',
    name: 'textActiveSessionActiveSessionActiveSessionH1ParkCharge',
    texts: {
      swe: 'Parkera & Ladda ',
      eng: 'Park & Charge'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '-0.36px',
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Mobile App'
  },
  textActiveSessionActiveSessionActiveSessionOffenseTitle: {
    characters: 'Offense',
    name: 'textActiveSessionActiveSessionActiveSessionOffenseTitle',
    texts: {
      swe: 'Felparkering',
      eng: 'Offense'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '-0.36px',
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Mobile App'
  }
}

export default Texts
