import dayjs from 'dayjs'

export const THREE_DAYS_AGO = dayjs().subtract(3, 'day').format()
export const THREE_DAYS_INTO_FUTURE = dayjs().add(3, 'day').format()
export const FIVE_MINUTES_AGO = dayjs().subtract(5, 'minute')
export const FIVE_MINUTES_INTO_FUTURE = dayjs().add(5, 'minute').format()

export const HALF_SECOND = 500
export const ONE_SECOND = 1000
export const TWO_SECONDS = 2000
export const FIVE_SECONDS = ONE_SECOND * 5
export const THIRTY_SECONDS = 30 * 1000
export const ONE_MINUTE = 60 * 1000
export const JIRA_ISSUES_ONE_HOUR_STALE = 3600000 //1h
export const ONE_DAY_IN_SECONDS = 24 * 60 * 60

export const YYYYMMDD_HHmm = 'YYYY-MM-DD HH:mm'
export const YYYYMMDD_HHmmss = 'YYYY-MM-DD HH:mm:ss'
export const HHmm = 'HH:mm'
export const YYYY_MM_DD = 'YYYY-MM-DD'
export const MMM_YYYY = 'MMM YYYY'
export const MMMM_YYYY = 'MMMM YYYY'
export const MMM_DD = 'MMM DD'
export const MMM_D = 'MMM D'
export const MMMM = 'MMMM'
export const YYYY = 'YYYY'

export const ONE_YEAR_IN_QUARTER = 4
export const ONE_YEAR_AND_HALF_IN_MONTHS = 18
export const EUROPE_STOCKHOLM = 'Europe/Stockholm'
