import { TextKey } from '@pure/emu/Antiloop'

import { Spacings } from '@guest-parking/enums/Spacings'
import Images from '@guest-parking/libs/Images'

import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export function HomeErrorMessage({ textKey, text }: { textKey: TextKey; text?: string }) {
  return (
    <Box direction="row" fullWidth>
      <FigmaImageContainer imageKey={Images.alertTriangleRed} />
      <Box left spacing={Spacings.xxs}>
        <FigmaText textKey={textKey} text={text} />
      </Box>
    </Box>
  )
}
