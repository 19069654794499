import { GetParkingSessionPermitEventData } from '@contracts/types/GetParkingSessionData'
import { GetPermitResultForSession } from '@contracts/types/GetPermitResultForSession'

import { isValidPermitSlot } from '@pure/libs/permit/isValidPermitSlot'
import { isValidPermitTime } from '@pure/libs/permit/isValidPermitTime'

export const isValidPermit = (req: GetParkingSessionPermitEventData): GetPermitResultForSession => {
  let res: GetPermitResultForSession
  res = isValidPermitTime(req)
  if (!res.result) {
    return res
  }

  res = isValidPermitSlot(req)
  if (!res.result) {
    return res
  }

  // TODO WRITE TEST, should return what permit is valid
  if (res.result) res.messages = [`Found valid permit: ${res.permit?.name} (${res.permit?.id})`]
  const finalResult = { ...res, result: true }
  return finalResult
}
