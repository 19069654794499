import { Period } from '@contracts/types/Period'
import { PermitValidityPeriod } from '@contracts/types/PermitTemplate'
import { Site } from '@contracts/types/Site'

import { DayJS } from '@pure/libs/DayJsHelper'
import { getTimezoneForSite } from '@pure/libs/SiteHelper'

export function getPeridForParking({ now, site }: { now?: string; site?: Site }): Period {
  const timezone = getTimezoneForSite(site)

  const dateTime = DayJS(now).tz(timezone)

  return { dayOfWeek: dateTime.day(), hour: dateTime.hour() }
}
export function isPeriodValid({
  period,
  validityPeriod
}: {
  period: Period
  validityPeriod: PermitValidityPeriod
}): boolean {
  return (
    period.dayOfWeek === validityPeriod.dayOfWeek &&
    period.hour >= validityPeriod.startHour &&
    period.hour < validityPeriod.endHour
  )
}
