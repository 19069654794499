import { jwtDecode } from 'jwt-decode'
import useQueryParams from 'pure/libs/useQueryParams'

import { FreeGuestParkingTokenPayload } from '@contracts/types/FreeGuestParkingTokenPayload'
import { GuestParkingFeatureQueryParams } from '@contracts/types/GuestParkingFeatureQueryParams'

export function useFreeGuestParkingTokenPayload(
  freeGuestParkingToken = useFreeGuestParkingToken()
): FreeGuestParkingTokenPayload | undefined {
  if (!freeGuestParkingToken) return undefined
  return jwtDecode(freeGuestParkingToken)
}

export function useFreeGuestParkingToken(): string | undefined {
  return useQueryParams<GuestParkingFeatureQueryParams>().freeGuestParkingToken
}
