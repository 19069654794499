import { Spacings } from '@contracts/enums/Spacings'

import Images from '@pure/assets/PayImages'
import { ImageKey, TextKey } from '@pure/emu/Antiloop'
import { Printable } from '@pure/libs/TextRepositoryHelper'

import { HardCodedTexts } from '@pay/libs/HardCodedTexts'

import Box from './Box'
import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'

export const ActiveSessionWidgetRowPrice = ({
  textKey,
  icon,
  rightValue,
  rightValueSuffixElement
}: {
  textKey: TextKey
  icon?: ImageKey
  rightValue: Printable
  rightValueSuffixElement?: React.ReactElement
}) => {
  return (
    <Box fullWidth direction="row" align="center" justify="space-between">
      <Box direction="row" align="center">
        <Box visibility={icon ? 'visible' : 'hidden'}>
          <FigmaImage imageKey={icon || Images.chargingIcon} />
        </Box>
        <Box left spacing={Spacings.s}>
          <FigmaText textKey={textKey} />
        </Box>
      </Box>
      <Box direction="row" align="center">
        <Box alignText="center">
          {rightValue ? <FigmaText textKey={HardCodedTexts.textUnpayedSessionPrice} text={`${rightValue}`} /> : null}
          {rightValueSuffixElement}
        </Box>
      </Box>
    </Box>
  )
}
