import styled from '@emotion/styled'

import { Colors } from '@pure/assets/Colors'

import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

export const LinkStyles = `
  cursor: pointer;
  :hover {
    text-decoration: underline;
  };
`

export const NoWrapTextstyles = `
  white-space: nowrap;
`
export const NoWrapFigmaText = styled(FigmaText)`
  ${NoWrapTextstyles}
`

export const NoWrapFigmaTextWithStyle = styled(FigmaTextWithStyle)`
  ${NoWrapTextstyles}
`

export const NoLineHeightFigmaText = styled(FigmaText)`
  line-height: 0;
`

export const Line = styled(Flexbox)<{ color: string; opacity?: number }>`
  height: 1px;
  width: 100%;
  background-color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity || 1};
`

export const GreyLine = styled(Flexbox)<{ color?: string }>`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ color = Colors.gray200 }) => color};
`

export const BlackLine = styled(Flexbox)`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${Colors.black};
`

export const getRoundImageStyles = (size: number) => `
  height: ${size}px;
  width: ${size}px;
  border-radius: ${size / 2}px;
`

export const RoundImage = styled.img<{ size: number }>`
  ${({ size }) => getRoundImageStyles(size)}
`

export const NormalFontWight = 'font-weight: 400;'
