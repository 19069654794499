import type FirebaseFunctions from 'firebase/functions'
import { httpsCallable } from 'firebase/functions'
import invariant from 'invariant'

import { AuthorizedRequest } from '@contracts/types/AuthorizedRequest'
import { CloudFunctions } from '@contracts/types/CloudFunctionTypes'

import { Log } from '@pure/libs/Log'

import { Auth } from '@firebase/auth'

import { captureException } from './SentryHelper'

export const callFunction = ({
  cloudfunction,
  data,
  functions
}: {
  cloudfunction: CloudFunctions
  data?: any
  functions: FirebaseFunctions.Functions
}) =>
  httpsCallable(
    functions,
    cloudfunction
  )(data)
    .then(({ data }) => Promise.resolve(Log.info(`Success ${cloudfunction}`)).then(() => data))
    .catch((e) => {
      captureException(e, (scope) => {
        scope.addBreadcrumb({ message: `${cloudfunction} request:`, data: { data } })
        scope.setTransactionName(cloudfunction)
        scope.setFingerprint([cloudfunction])
        return scope
      })

      return Promise.reject(e)
    })

export const callFunctionWithAuthorized = <T>({
  auth,
  cloudfunction,
  data,
  functions
}: {
  auth: Auth
  cloudfunction: CloudFunctions
  data?: T
  functions: FirebaseFunctions.Functions
}) =>
  authorizeRequest(data, auth)
    .then((req: AuthorizedRequest<T>) => httpsCallable(functions, cloudfunction)(req))
    .then(({ data }) => Promise.resolve(console.log('Success', cloudfunction)).then(() => data))
    .catch((e) => {
      e.message = `${cloudfunction}: ${e.message}`
      captureException(e, (scope) => {
        scope.addBreadcrumb({ message: `${cloudfunction} request:`, data: { data } })
        scope.setTransactionName(cloudfunction)
        scope.setFingerprint([cloudfunction])
        return scope
      })

      return Promise.reject(e)
    })

const authorizeRequest = <T>(data: T, auth: Auth): Promise<AuthorizedRequest<T>> => {
  invariant(auth.currentUser, 'User must be logged in to call a cloud function')
  return auth.currentUser
    .getIdTokenResult()
    .then(({ token }) => token)
    .then((idToken) => {
      return { ...data, idToken }
    })
}
