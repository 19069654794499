import { ApiError, CustomRequestInit, Middleware } from 'openapi-typescript-fetch/dist/cjs/types'

import { SlackWebhooks } from '@contracts/enums/SlackWebhooks'

import { captureServerError } from './SentryHelper'
import { slack } from './SlackHelperClient'

export const ApiHandlerFetchClientLogger: Middleware = async (url, init, next) => {
  try {
    return await next(url, init)
  } catch (e: any) {
    init.body = maskCardNumbers(init.body)

    onApiError(e, url, init)
    if (e?.status === 404 && url?.includes('/active-session')) return e
    throw e
  }
}

export const onApiError = (err: ApiError, url: string, init: CustomRequestInit) => {
  const method = init.method
  if (err.status === 404) return
  if (err.status === 401) return

  captureServerError(err, url, init)

  if (!err.status) return slack((err as Error).stack || '', SlackWebhooks.OPS_API_ERRORS)

  return slack(
    `${method} ${url} ${err.status} ${err.statusText}
${err.data ? `data: ${JSON.stringify(err.data)}` : ''}
${init.body ? `body: ${init.body}` : ''}`,
    SlackWebhooks.OPS_API_ERRORS
  ).finally(() => ({}))
}

export const maskCardNumbers = (input?) => {
  if (!input) return input
  if (!input.replace) return input // replace can be many other things than strings https://drifterworldgroup.slack.com/archives/C0634JFU85S/p1698669674807239
  const regex = /(\d{12})(\d{4})/g
  return input.replace(regex, '************$2')
}
