import { onAuthStateChanged } from 'firebase/auth'
import invariant from 'invariant'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { captureException } from '@web-js/libs/SentryHelper'

import LoginEnterCode from '@guest-parking/components/SignInEnterCode'
import SignInEnterPhoneNumber from '@guest-parking/components/SignInEnterPhoneNumber'
import * as SignInMachineHelper from '@guest-parking/components/SignInMachine'
import { SignInMachineServices } from '@guest-parking/components/SignInMachineHelper'
import * as startSignInWithCode from '@guest-parking/components/startSignInWithCode'
import useAppState from '@guest-parking/hooks/useAppState'
import { auth } from '@guest-parking/libs/FirebaseOptions'
import { SignInState, SignInSteps, SignInViewProps } from '@guest-parking/types/SignInTypes'

export const DEFAULT_SIGN_IN_STATE: SignInState = {
  data: {
    phone: ''
  },
  step: SignInSteps.LANDING
}

const LogInViews = {
  [SignInSteps.LANDING]: SignInEnterPhoneNumber,
  [SignInSteps.ENTER_CODE]: LoginEnterCode
}

const SignInScreen = () => {
  const Views = LogInViews
  const { state, setState } = useAppState()
  const [isLoading, setIsLoading] = useState(false)
  const [signInState, setSignInState] = useState<SignInState>(DEFAULT_SIGN_IN_STATE)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) =>
      Promise.resolve()
        .then(() => {
          if (!firebaseUser) return

          setState({ ...state, firebaseUser })
        })
        .catch(captureException)
    )
  }, [])

  useEffect(() => {
    if (state.firebaseUser) {
      navigate(searchParams.get('redirectTo') || '/')
    }
  }, [state.firebaseUser])

  const signInViewProps: SignInViewProps = {
    isLoading,
    signInState,
    onPressBack: () => setSignInState({ ...DEFAULT_SIGN_IN_STATE, step: SignInSteps.LANDING }),
    onPressSignup: () => setSignInState({ ...signInState, step: SignInSteps.ENTER_CODE }),
    onPressResendCode: () => startSignInWithCode.startSignInWithCode(signInState, state, SignInMachineServices),
    onPressContinue: (signInState) =>
      Promise.resolve(setIsLoading(true))
        .then(() => SignInMachineHelper.onPressContinue(signInState, state, SignInMachineServices))
        .then((signInState: SignInState) => setSignInState(signInState))
        .catch(captureException)
        .finally(() => setIsLoading(false))
  }

  const Component: React.FC<SignInViewProps> = Views[signInViewProps.signInState.step]

  invariant(Component, `Can't find onboarding step for %s`, signInViewProps.signInState.step)

  return <Component {...signInViewProps} />
}

export default SignInScreen
