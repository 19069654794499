import { FieldValue } from 'firebase-admin/firestore'

import { DisabilityPermit, Permit } from '@contracts/types/Permit'

import { Language } from '@pure/emu/Antiloop'
import { components } from '@pure/swagger/DrifterTypes'

export type CreateUserRequestBase = {
  name?: string
  locale?: Language
  email?: string
  phone: string
  uid?: string
  plates?: string[]
}

export type CreateUserRequest = CreateUserRequestBase & {
  userId?: string
}

export type HikerUser = CreateUserRequest &
  DrifterUserObject & {
    siteIds?: string[]
    role?: HikerUserRole
    stripeId?: string
    email?: string
    defaultPaymentMethodId?: string
    expoPushNotificationToken?: string
    permits?: {
      [permitId: string]: Permit | FieldValue // FieldValue is used to mark properties for deletion in Firestore when using merge:true in .set(user, { merge: true })
    }
    disabledUser?: boolean
    disabilityPermit?: DisabilityPermit
  }

// Ported from old schema, might still contain obsolete fields
export type DrifterUserObject = {
  id: string
  /** @example Demo */
  firstName?: string
  /** @example User */
  lastName?: string
  /** @example 123456789 */
  ssn?: string
  /** @example */
  profilePhotoUrl?: string
  /** @example true */
  emailVerify?: boolean
  /** @example Vinvägen 9 */
  street?: string
  /** @example SV */
  country?: string
  /** @example */
  zipCode?: string
  /** @example Stockholm */
  city?: string
  vehicles?: components['schemas']['vehicles-object']
}

export type DrifterUser = components['schemas']['user']

export enum HikerUserRole {
  ROOT = 'ROOT',
  ADMIN = 'ADMIN',
  VIEWER = 'VIEWER'
}
