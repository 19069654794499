export const HardCodedTexts = {
  textPaymentsSwishEnterPhoneNumber: {
    characters: 'Enter phone number',
    name: 'textPaymentsSwishEnterPhoneNumber',
    texts: {
      swe: 'Ange telefonnummer',
      eng: 'Enter phone number'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(53, 71, 100, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textUnpayedSessionPrice: {
    characters: 'kr',
    name: 'textUnpayedSessionPrice',
    texts: {
      swe: 'kr',
      eng: 'kr'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textServiceStartsSoon: {
    characters: 'This parking service will start on XXX.',
    name: 'textServiceStartsSoon',
    texts: {
      swe: 'Den här parkeringstjänsten börjar gälla den XXX.',
      eng: 'This parking service will start on XXX.'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(233, 213, 2, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textServiceUnavailable: {
    characters: 'Parking is currently free.',
    name: 'textServiceStartsSoon',
    texts: {
      swe: 'Parkering är för närvarande gratis.',
      eng: 'Parking is currently free.'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(233, 213, 2, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptFreeParking: {
    characters: '',
    name: 'textPaymentsReceiptCssReceiptDetailsStreetName',
    texts: {
      swe: 'Gratis Parkering: XXX min per dag',
      eng: 'Free Parking: XXX min per day'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(124, 124, 124, 1)',
      opacity: 0.6000000238418579
    },
    pageName: 'Payment Flows QR'
  },
  textGoBack: {
    characters: '',
    name: 'textGoBack',
    texts: {
      swe: 'Tillbaka',
      eng: 'Go Back'
    },
    style: {
      fontFamily: 'Inter',
      fontWeight: 500,
      textDecoration: 'underline'
    },
    pageName: 'Payment Flows QR'
  }
}
