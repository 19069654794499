export enum Spacings {
  xxs = '4px',
  xs = '8px',
  s = '16px',
  m = '24px',
  l = '32px',
  xl = '40px',
  xxl = '48px',
  xxxl = '64px'
}
