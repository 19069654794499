import * as Sentry from '@sentry/react'
import { CaptureContext } from '@sentry/types/types/scope'
import { ApiError } from 'openapi-typescript-fetch'
import { CustomRequestInit } from 'openapi-typescript-fetch/dist/cjs/types'

import { API_URL } from '@consts/HikerConsts'

const USER_EMAIL = 'userEmail'
const USER_ID = 'userId'

type ServerErrorData = {
  success?: boolean
  errors?: string
  message?: string
}

export const captureException = (err, captureContext?: CaptureContext) => {
  if (err.url) return // Api errors are caught by the ApiHandlerFetchClientLogger
  Sentry.captureException(err, captureContext as any)
}

export function captureServerError(err: ApiError, url: string, init: CustomRequestInit) {
  const _err: ApiError = err
  const { status, statusText } = _err
  const data: ServerErrorData = _err.data
  const method = init.method || ''
  url = url.replace(API_URL, '')
  const message = JSON.stringify({ method, url, status, statusText, data })

  Sentry.captureException(new Error(message), (scope) => {
    const fingerprint = [method, url, _err.status.toString(), _err.statusText]
    scope.setFingerprint(fingerprint)
    scope.setTransactionName(fingerprint.join(' '))
    return scope
  })
}

export const setSentryUser = (user: Sentry.User) => {
  Sentry.setUser(user)
  Sentry.setTag(USER_EMAIL, user.email)
  Sentry.setTag(USER_ID, user.id)
}
