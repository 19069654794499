import React from 'react'

import { ImageKey } from '@pure/emu/Antiloop'

import * as Images from '@guest-parking/libs/ImagesRepository'

const FigmaImage: React.FC<{
  imageKey: ImageKey
  imageStyle?: { [name: string]: string | number | undefined }
}> = ({ imageKey, imageStyle }) => {
  const { width, height } = imageKey
  const component = Images[imageKey.name]
  const style = { ...(!!imageStyle && imageStyle) }
  if (!component) return null

  if (imageKey.format === 'svg')
    return React.createElement(component, {
      style,
      alt: imageKey.name,
      'data-testid': imageKey.name,
      width,
      height
    })

  return <img src={component} style={style} alt={imageKey.name} />
}

export default FigmaImage
