import { doc } from 'firebase/firestore'
import { useQuery } from 'react-query'

import Collections from '@contracts/enums/Collections'
import { Site } from '@contracts/types/Site'

import { createUniqueId } from '@pure/libs/Common'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { State } from '@guest-parking/hooks/useAppState'
import { getParkIdFromUrl } from '@guest-parking/hooks/useParking'

import { db } from './FirebaseOptions'
import { getApiV2IsCarInParking } from './LBEApiHandlerDeprecated'

export enum QueryKeys {
  GET_IS_CAR_IN_PARKING = 'GET_IS_CAR_IN_PARKING'
}

export const useGetIsCarInParking = (state: State) =>
  useQuery([QueryKeys.GET_IS_CAR_IN_PARKING], () => getApiV2IsCarInParking(state))

export const useSiteForWindow = (window: Window): Site | undefined => {
  const _siteId = getParkIdFromUrl(window.location.href)
  const siteId = _siteId?.toString() || '0'
  const { data: site } = useSite(siteId)
  return site
}

export const useSite = (id: string) =>
  useFirestoreQuery<Site>(doc(db, Collections.SITES, id || createUniqueId()), { fnName: 'useSite', enabled: !!id })
