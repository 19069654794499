import 'react-toastify/dist/ReactToastify.css'
import './libs/FirebaseOptions'
import './libs/Fonts'

import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import RoutePath from '@contracts/enums/RoutePath'

import { GuestParkingPermitsList } from '@guest-parking/components/GuestParkingPermitsList'
import { GuestParkingQRCode } from '@guest-parking/components/GuestParkingQRCode'
import ProtectedRoute from '@guest-parking/components/ProtectedRoute'

import ErrorBoundaryProvider from './components/ErrorBoundaryProvider'
import { Context, useAppStateContext } from './hooks/useAppState'
import { MaterialTheme } from './libs/MaterialTheme'
import { GuestParkingInvalidTokenScreen } from './screens/GuestParkingInvalidTokenScreen'
import { GuestParkingScreen } from './screens/GuestParkingScreen'
import SignInScreen from './screens/SignInScreen'
import { AuthProvider } from './store/AuthProvider'

function App() {
  const context = useAppStateContext()
  const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } })

  return (
    <ErrorBoundaryProvider>
      <ThemeProvider theme={MaterialTheme}>
        <Context.Provider value={context}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AuthProvider>
                <Routes>
                  <Route path="/" Component={GuestParkingScreen} />
                  <Route path={RoutePath.SIGN_IN_GUEST_PARKING} Component={SignInScreen} />
                  <Route path={RoutePath.ON_NEW_GUEST_PARKING} Component={GuestParkingScreen} />
                  <Route path="/sandbox" Component={GuestParkingInvalidTokenScreen} />
                  <Route
                    path="/:siteId"
                    element={
                      <ProtectedRoute>
                        <GuestParkingQRCode />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={RoutePath.NEW_GUEST}
                    element={
                      <ProtectedRoute>
                        <GuestParkingScreen />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/site/:siteId"
                    element={
                      <ProtectedRoute>
                        <GuestParkingPermitsList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/site/:siteId/permit/:permitId"
                    element={
                      <ProtectedRoute>
                        <GuestParkingQRCode />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </AuthProvider>
              <ToastContainer />
            </BrowserRouter>
          </QueryClientProvider>
        </Context.Provider>
      </ThemeProvider>
    </ErrorBoundaryProvider>
  )
}

export default App
