import { useEffect, useState } from 'react'

import { formatLocalStorageItem, getInitialItem } from './useAppStateHelper'
import { DEFAULT_OPTIONS, getElligebleStorage, Options, StorageKey } from './useLocalStorageItemHelper'

//@ts-ignore
export const useStorageItem = <T>(
  key: StorageKey,
  defaultValue: T,
  { ignoredKeys = [], storageType }: Options = DEFAULT_OPTIONS
) => {
  const Storage = getElligebleStorage(storageType)
  const stored = Storage.getItem(key)
  const initial = getInitialItem<T>(stored, defaultValue)

  const [storageItem, setStorageItem] = useState<T>(initial)

  useEffect(() => {
    try {
      let _storageItem = storageItem
      // eslint-disable-next-line prettier/prettier
      if(ignoredKeys.length > 0) _storageItem = formatLocalStorageItem(_storageItem, ignoredKeys)
      Storage.setItem(key, JSON.stringify(_storageItem))
    } catch (error) {
      console.warn(error)
    }
  }, [key, storageItem])

  return { storageItem, setStorageItem }
}
