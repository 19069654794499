import { en, LANGUAGES } from '@consts/Locale'

import { Language } from '@pure/emu/Antiloop'
import { getObjectOrBoolForJSON } from '@pure/libs/Common'

import { State } from './useAppState'
import { StorageKey } from './useLocalStorageItemHelper'

// use web-js use language instead
export const getLanguageCode = (): Language => {
  // TODO WRITE TEST, should be able to render email on server
  if (typeof window === 'undefined') return en
  const json = window.localStorage.getItem(StorageKey.STATE)
  const defaultLanguageCode = getDefaultLanguageCode()
  if (!json) return defaultLanguageCode
  const state = getObjectOrBoolForJSON(json) as State | boolean
  if (!state) return defaultLanguageCode

  return (state as State).user.locale || defaultLanguageCode
}

export function getDefaultLanguageCode(): Language {
  const defaultLanguageCode = navigator.language?.substr(0, 2) as Language | undefined
  if (!defaultLanguageCode) return en
  if (LANGUAGES.includes(defaultLanguageCode)) return defaultLanguageCode
  return en
}
