import dayjs from 'dayjs'

import { GuestParkingMachine } from '@guest-parking/components/GuestParkingMachine'
import config from '@guest-parking/Config'
import { useFreeGuestParkingTokenPayload } from '@guest-parking/hooks/useFreeGuestParkingToken'

import { GuestParkingInvalidTokenScreen } from './GuestParkingInvalidTokenScreen'

export const GuestParkingScreen = () => {
  const freeGuestParkingToken = useFreeGuestParkingTokenPayload()

  const tokenDate = dayjs(freeGuestParkingToken?.date)
  const isTokenDateValid = tokenDate.isSame(dayjs(), 'day') || dayjs().isBefore(tokenDate)

  if ((!config.enableAlwaysShowForm && !isTokenDateValid) || !freeGuestParkingToken) {
    return <GuestParkingInvalidTokenScreen />
  }

  return <GuestParkingMachine />
}
