import ButtonPrimary, { ButtonProps } from './ButtonPrimary'

export default function Button(props: ButtonProps) {
  const { buttonType, ...buttonProps } = props

  if (buttonType === 'white') return <ButtonPrimary {...buttonProps} />
  if (buttonType === 'secondary') return <ButtonPrimary {...buttonProps} />
  if (buttonType === 'tertiary') return <ButtonPrimary {...buttonProps} />
  if (buttonType === 'empty_state') return <ButtonPrimary {...buttonProps} />
  return <ButtonPrimary {...buttonProps} />
}
