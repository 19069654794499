import styled from '@emotion/styled'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'

import { Spacings } from '@guest-parking/enums/Spacings'
import { useFreeGuestParkingTokenPayload } from '@guest-parking/hooks/useFreeGuestParkingToken'
import { GuestParkingViewProps } from '@guest-parking/libs/GuestParkingHelper'
import { useSite } from '@guest-parking/libs/ReactQueryHooks'
import { getFigmaText } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { GuestParkingFormInfoBox } from './GuestParkingFormInfoBox'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import { Layout } from './Layout'
// import { useGetGuestLogo } from '../../../web-js/src/libs/ReactQueryHooks'

const GuestParkingConditions = (props: GuestParkingViewProps) => {
  const handleClick = () => {
    if (props.isLoading) return

    return props.onPressContinue({ ...props.guestParkingState })
  }

  const payload = useFreeGuestParkingTokenPayload()
  const { data: site } = useSite(payload?.siteId || '0')

  const conditions =
    `${getFigmaText(Texts.guestQrParkingConditionsCssText)}\n\n${site?.guestParkingAdditionalConditions}`.trim()

  return (
    <Layout>
      <Box fullWidth top left right spacing={Spacings.xl}>
        <Box fullWidth align="center">
          <GuestParkingSiteLogo site={site} />
        </Box>
        <Box fullWidth align="center" top bottom spacing={Spacings.l}>
          <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingQrLandingHeader} />
        </Box>
        <Box fullWidth align="center" bottom spacing={Spacings.l}>
          <FigmaText textKey={Texts.guestQrParkingConditionsHeader} />
        </Box>
        <Box bottom>
          <FigmaText textKey={Texts.guestQrParkingConditionsCssText} text={conditions} />
        </Box>
        <Button
          fullWidth
          textKey={Texts.textButtonsStartGuestParking}
          onClick={handleClick}
          loading={props.isLoading}
        />
        <Box fullWidth top bottom spacing={Spacings.xxl}>
          <GuestParkingFormInfoBox />
        </Box>
      </Box>
    </Layout>
  )
}

const InformationBox = styled(Box)`
  margin-top: ${Spacings.l};
  margin-bottom: ${Spacings.xxxl};
  border: 1px solid grey;
  border-radius: ${BorderRadiusesPx.lightlyRounded};
`

export default GuestParkingConditions
