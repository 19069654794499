export const HardCodedTexts = {
  textPaymentsSwishEnterPhoneNumber: {
    characters: 'Enter phone number',
    name: 'textPaymentsSwishEnterPhoneNumber',
    texts: {
      swe: 'Ange telefonnummer',
      eng: 'Enter phone number'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(53, 71, 100, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textUnpayedSessionPrice: {
    characters: 'kr',
    name: 'textUnpayedSessionPrice',
    texts: {
      swe: 'kr',
      eng: 'kr'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textGuestParkingNoGuestPermitsForSite: {
    characters: 'There is no guest parking permit available for this site.',
    name: 'textGuestParkingNoGuestPermitsForSite',
    texts: {
      swe: 'There finns inget gästparkeringsstillstånd tillgängligt för denna plats.',
      eng: 'There is no guest parking permit available for this site.'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(53, 71, 100, 1)'
    },
    pageName: 'Guest Feature'
  }
}
