import { GetParkingSessionEventData } from '@contracts/types/GetParkingSessionData'
import { GetPermitResultForSession } from '@contracts/types/GetPermitResultForSession'

import { removeDuplicateStrings } from '@pure/libs/Common'
import { isValidPermit } from '@pure/libs/permit/isValidPermit'

export const hasValidPermit = (data: GetParkingSessionEventData): GetPermitResultForSession =>
  data.permits.reduce(
    (a, permit) => {
      if (a.result) return a

      const result = isValidPermit({
        ...data,
        permit
      })

      if (result.result) return result

      const messages = removeDuplicateStrings(a.messages.concat(result.messages))
      return { result: false, messages: messages, permit }
    },
    { result: false, messages: [] as string[] } as GetPermitResultForSession
  )
