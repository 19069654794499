import React, { useEffect, useRef, useState } from 'react'

import { mapCssHeightToNumber } from '@pure/libs/Common'

import Box from './Box'
import FigmaText from './FigmaText'
import Loader from './Loader'

type Props = any // FigmaTextProps & { loading?: boolean; Component?: React.FC<any> }
const FigmaTextLoader: React.FC<Props> = (props) => {
  const { Component = FigmaText, loading, ...figmaTextProps } = props
  const { textKey, styleTextKey } = figmaTextProps
  const style = (styleTextKey || textKey)?.style || {}
  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const textHeight = mapCssHeightToNumber(style.fontSize)

  useEffect(() => {
    ref.current && setHeight(ref.current.clientHeight)
  })

  if (loading)
    return (
      <Box position="relative">
        <Box visibility="hidden" ref={ref}>
          <Component {...figmaTextProps} />
        </Box>
        <Box position="absolute" style={{ top: (height - textHeight) / 2, left: 0, right: 0, margin: 'auto' }}>
          <Loader $loaderColor={style.color} size={style.fontSize} />
        </Box>
      </Box>
    )

  return <Component {...figmaTextProps} />
}

export default FigmaTextLoader
