import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import onUnhandledPromiseRejection from './libs/onUnhandledPromiseRejection'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

window.addEventListener('unhandledrejection', ({ promise, reason: error }) => onUnhandledPromiseRejection(error))
