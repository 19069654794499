import { getApiV2Parking } from '@web-js/libs/ApiHandler'

import { useAsyncFunction } from './useAsyncFunction'

export const useParking = (window) => {
  const parkId = getParkIdFromUrl(window.location.href)
  return useAsyncFunction(() => getApiV2Parking({ parkId: parkId as number }), {
    enabled: !!parkId,
    key: parkId?.toString()
  })
}

export const getParking = (window) => {
  const parkId = getParkIdFromUrl(window.location.href)
  return parkId ? getApiV2Parking({ parkId }) : Promise.resolve()
}

export const getParkIdFromUrl = (url: string) => {
  const parkIdStr = url.match(/\/(\d+)$/)?.[1] || ''
  if (!parkIdStr) return undefined
  const parkId = parseInt(parkIdStr)
  if (!parkId) return undefined
  return parkId
}
