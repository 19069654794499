import styled from '@emotion/styled'
import dayjs from 'dayjs'

import { SessionEventName } from '@contracts/types/Session'

import { Spacings } from '@guest-parking/enums/Spacings'
import { useFreeGuestParkingTokenPayload } from '@guest-parking/hooks/useFreeGuestParkingToken'
import { GuestParkingViewProps } from '@guest-parking/libs/GuestParkingHelper'
import { useSite } from '@guest-parking/libs/ReactQueryHooks'
import Texts from '@guest-parking/libs/Texts'

import ActiveSessionWidgetFree from '@pay/components/ActiveSessionWidgetFree'

import { AppStoreButtons } from './AppStoreButtons'
import Box from './Box'
import FigmaText from './FigmaText'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import { Layout } from './Layout'

const GuestParkingInformation = (props: GuestParkingViewProps) => {
  const payload = useFreeGuestParkingTokenPayload()
  const { data: site } = useSite(payload?.siteId || '0')
  const { guestParkingState } = props
  const { session } = guestParkingState.data

  if (session?.parkingSession) {
    const { startedAt } = session.parkingSession

    session.parkingSession.events = [
      ...session.parkingSession.events,
      {
        name: SessionEventName.PARKING_FREE,
        startedAt,
        endedAt: dayjs().endOf('day').format()
      }
    ]
  }

  return (
    <Layout>
      <Box fullWidth fullPadding spacing={Spacings.xl}>
        <Box fullWidth align="center">
          <GuestParkingSiteLogo site={site} />
        </Box>
        <Box fullWidth align="center" top bottom spacing={Spacings.l}>
          <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingQrLandingHeader} />
        </Box>
        <ActiveSessionWidgetFree hideTime session={session} site={site} />
        <Box align="center" top bottom spacing={Spacings.xxxl} alignText="center">
          <FigmaText textKey={Texts.textPaymentsQrLandingH1MarketTheApp} />
          <Box top spacing={Spacings.xl}>
            <AppStoreButtons />
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

const StyledImg = styled.img`
  max-width: 250px;
`

const TextButton = styled(Box)`
  cursor: pointer;
`

export default GuestParkingInformation
