import React from 'react'

import { Colors } from '@pure/assets/Colors'
import { TextStyles } from '@pure/assets/TextStyles'
import { TextKey } from '@pure/emu/Antiloop'

import FigmaText, { FigmaTextProps } from './FigmaText'

export type FigmaTextWithStyleProps = {
  type: keyof typeof TextStyles
  color?: keyof typeof Colors
  opacity?: number
  Component?: React.FC<FigmaTextProps>
} & FigmaTextProps

export const FigmaTextWithStyle = (props: FigmaTextWithStyleProps) => {
  const { type, opacity = 1, Component = FigmaText, textKey } = props
  const color = props.color || textKey?.style.color || 'primary'

  const styleTextKey: TextKey = {
    ...TextStyles[type],
    texts: {},
    pageName: '',
    characters: '',
    style: {
      ...TextStyles[type].style,
      color: Colors[color || 'primary'],
      opacity
    }
  }

  return <Component {...props} styleTextKey={styleTextKey} />
}
