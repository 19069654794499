import { OffenseType } from '@contracts/types/OffenseType'

import Images from '@pay/libs/Images'
import { DeprecatedTexts } from '@pay/libs/Texts'

import Texts from '@roamer/libs/Texts'

export const getActiveSessionHeadingElements = (isParkAndCharge: boolean | undefined, isOffense: boolean) => {
  if (isOffense)
    return {
      activeSessionTypeTextKey: DeprecatedTexts.textActiveSessionActiveSessionActiveSessionOffenseTitle,
      activeSessionTypeIcon: Images.activeSessionPark,
      infoIcon: Images.infoIconPurple
    }
  if (isParkAndCharge)
    return {
      activeSessionTypeTextKey: DeprecatedTexts.textActiveSessionActiveSessionActiveSessionH1ParkCharge,
      activeSessionTypeIcon: Images.activeSessionParkAndCharge,
      infoIcon: Images.infoIconPurple
    }
  return {
    activeSessionTypeTextKey: DeprecatedTexts.textActiveSessionActiveSessionActiveSessionH1Park,
    activeSessionTypeIcon: Images.activeSessionPark,
    infoIcon: Images.infoIconPurple
  }
}

const getTextKeysForOffense = (sessionStatus: OffenseType) => {
  switch (sessionStatus) {
    case OffenseType.charge_slot_offense:
      return {
        headingTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseEvHeader,
        descriptionTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseEvText
      }
    case OffenseType.disabled_slot:
      return {
        headingTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseDisabledHeader,
        descriptionTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseQrDisabledText
      }
    case OffenseType.marked_slot:
      return {
        headingTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseMarkedSlotText,
        descriptionTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseDetails
      }
    case OffenseType.vehicle_position:
      return {
        headingTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseWronglyParkedText,
        descriptionTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseDetails
      }
    default:
      return {
        headingTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseWronglyParkedText,
        descriptionTextKey: Texts.textActiveSessionActiveSessionActiveSessionOffenseDetails
      }
  }
}

export const getOffenseInformation = (sessionStatus?: string | null) => {
  if (!sessionStatus) return { isOffense: false }
  const { headingTextKey, descriptionTextKey } = getTextKeysForOffense(sessionStatus as OffenseType)
  const typesOfOffenses = Object.values(OffenseType)

  if (typesOfOffenses.includes(sessionStatus as OffenseType)) {
    return { isOffense: true, headingTextKey, descriptionTextKey }
  }

  return { isOffense: false }
}
