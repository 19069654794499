import dayjs from 'dayjs'
import { useState } from 'react'

import { ONE_SECOND } from '@consts/DateConsts'

import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiuses } from '@contracts/types/BorderRadixes'
import { Session, SessionEventName } from '@contracts/types/Session'
import { Site } from '@contracts/types/Site'

import { Colors } from '@pure/assets/Colors'
import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { DayJS } from '@pure/libs/DayJsHelper'
import { getLastFreeSessionEvent } from '@pure/libs/SessionHelper'

import { getActiveSessionHeadingElements } from '@pay/helpers/ActiveSessionHelper'
import { getUntilText } from '@pay/libs/getUntilText'

import { useInterval } from '@web-components/hooks/useInterval'

import { ActiveSessionWidgetRowPrice } from './ActiveSessionWidgetRow'
import Box from './Box'
import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { SessionInformationContainer } from './SessionInformationContainer'
import { StationInfoDrawer } from './StationInfoDrawer'

const now = dayjs()

type Props = {
  session?: Session
  hideTime?: boolean
  site?: Site
}

export const ActiveSessionWidgetFree: React.FC<Props> = ({ session, site, hideTime }) => {
  const lastFreeSessionEndedAt = getLastFreeSessionEvent(session)?.endedAt
  const finishDate = dayjs(lastFreeSessionEndedAt)

  const [showParkDetails, setShowParkDetails] = useState(false)
  const [ellapsed, setEllapsed] = useState(0)

  useInterval(() => {
    setEllapsed((ellapsed) => ellapsed + ONE_SECOND)
  }, ONE_SECOND)

  const untilText = getUntilText(finishDate.format())

  const startedAt = session?.parkingSession?.startedAt
  const seconds = startedAt ? dayjs(now).add(ellapsed, 'milliseconds').diff(startedAt, 'second') : 0
  const formattedTime = DayJS.duration(seconds, 'seconds').format('HH:mm:ss')

  const { activeSessionTypeTextKey, activeSessionTypeIcon } = getActiveSessionHeadingElements(
    false,
    !!session?.parkingSession?.events?.find((event) => event.name === SessionEventName.PARKING_OFFENSE)
  )

  return (
    <SessionInformationContainer fullWidth>
      <StationInfoDrawer open={showParkDetails} onClose={() => setShowParkDetails(false)} site={site} />
      <Box fullWidth direction="row" align="center" justify="space-between" bottom spacing={Spacings.s}>
        <Box direction="row" align="center">
          <FigmaImage imageKey={activeSessionTypeIcon} />
          <Box left spacing={Spacings.s}>
            <FigmaText textKey={activeSessionTypeTextKey} text={site?.name ?? session?.site?.name} />
          </Box>
        </Box>
        <Box onClick={() => setShowParkDetails(true)} pointer>
          <FigmaImage imageKey={Images.infoIconPurple} />
        </Box>
      </Box>
      <Box fullWidth>
        <ActiveSessionWidgetRowPrice
          textKey={Texts.textActiveSessionActiveSessionActiveSessionTimeFreeParking}
          icon={Images.timeIcon}
          rightValue={hideTime ? undefined : formattedTime}
          rightValueSuffixElement={
            !hideTime && lastFreeSessionEndedAt ? (
              <FigmaTextWithStyle type="captionItallic" text={untilText} />
            ) : undefined
          }
        />
      </Box>
    </SessionInformationContainer>
  )
}

export default ActiveSessionWidgetFree

export const styles = {
  wrapper: {
    borderRadius: BorderRadiuses.lightlyRounded,
    borderWidth: 2,
    width: '100%',
    padding: Spacings.s,
    borderColor: Colors.brandPink,
    backgroundColor: Colors.white
  },
  title: {
    paddingLeft: Spacings.xs
  },
  totalTimer: {
    fontSize: 22,
    color: Colors.brandDarkBlue,
    fontWeight: 'bold'
  }
}
