import styled from '@emotion/styled'
import MaterialButton from '@mui/material/Button'

import { Spacings } from '@guest-parking/enums/Spacings'

export default styled(MaterialButton, { shouldForwardProp: (prop) => prop !== 'verticalSpacing' })<{
  spacing?: string
  verticalSpacing?: string
}>`
  padding-left: ${({ spacing }) => spacing || Spacings.m};
  padding-right: ${({ spacing }) => spacing || Spacings.m};
  padding-top: ${({ verticalSpacing }) => verticalSpacing || Spacings.s};
  padding-bottom: ${({ verticalSpacing }) => verticalSpacing || Spacings.s};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  min-width: auto;
  text-transform: none;
  border-radius: var(--radius-radius-full, 22.5rem);
`
