import { EUROPE_WEST_3 } from './Locale'

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyD4zw_bW1dIZkZcBrC1YPTZ0bXxECLGGtI',
  authDomain: 'roamer-60e9a.firebaseapp.com',
  projectId: 'roamer-60e9a',
  storageBucket: 'roamer-60e9a.appspot.com',
  messagingSenderId: '528949292174',
  appId: '1:528949292174:web:98cffa9550b7111b452ddf'
}

export const REGION = EUROPE_WEST_3

export const STORAGE_BUCKET_URL = 'gs://roamer-60e9a.appspot.com'

export const NO_REPLY_EMAIL_ADDRESS = 'no-reply@drifterworld.se'

export const FORTNOX_CLIENT_ID = 'a2Jrwn1MRhAb'

export const STRIPE_ROAMER_RETURN_URL = 'drifter://stripe-redirect'

export const API_URL = 'https://app.drifterworld.se'

export const GC_FILE_PATH_DESIGN_TOKENS = `emu/design-tokens.tokens.json`
export const GC_FILE_PATH_DESIGN_SYSTEM_TOKENS = `emu/design-tokens.tokens.system.json`
