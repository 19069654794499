// utils/useDeviceDetect.js
import React from 'react'

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false)

  React.useEffect(() => {
    const mobile = getIsMobile(window)
    setMobile(mobile)
  }, [])

  return { isMobile }
}

export function getIsMobile(w: Window) {
  const userAgent = typeof w.navigator === 'undefined' ? '' : w.navigator.userAgent
  const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i))
  return mobile
}
