import dayjs from 'dayjs'

import { Site } from '@contracts/types/Site'

export function getKeyForSiteNumberOfGuests() {
  return dayjs().format('YYYY-MM-DD')
}

export const getValueForSiteNumberOfGuests = (site?: Site) => {
  const key = getKeyForSiteNumberOfGuests()
  return site?.numberOfGuests?.[key] || 0
}
