import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { HikerUserRole } from '@contracts/types/HikerUser'

import { getParkIdFromUrl } from '@web-js/libs/getParkIdFromUrl'

import Button from '@guest-parking/components/Button'
import { Spacings } from '@guest-parking/enums/Spacings'
import useAppState from '@guest-parking/hooks/useAppState'
import { useAsyncFunction } from '@guest-parking/hooks/useAsyncFunction'
import { useLogout } from '@guest-parking/hooks/useLogout'
import { getGuestParkingSitePermits } from '@guest-parking/libs/CloudFunctionsApiHandler'
import { getUserByPhoneNumber } from '@guest-parking/libs/DBApiHandler'
import { auth } from '@guest-parking/libs/FirebaseOptions'
import { HardCodedTexts } from '@guest-parking/libs/HardCodedTexts'
import { useSite } from '@guest-parking/libs/ReactQueryHooks'
import Texts from '@guest-parking/libs/Texts'
import { GuestParkingNotAuthorizedScreen } from '@guest-parking/screens/GuestParkingNotAuthorizedScreen'

import Box from './Box'
import FigmaText from './FigmaText'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import Loader from './Loader'

export const GuestParkingPermitsList = () => {
  const logout = useLogout()
  const _siteId = getParkIdFromUrl(window.location.href)
  const siteId = _siteId?.toString() || '0'
  const { state } = useAppState()
  const navigate = useNavigate()

  const [idToken, setIdToken] = useState<string>('')
  const [numberOfGuestPermits, setNumberOfGuestPermits] = useState<number>(0)
  const { data: site, isLoading: isLoadingSite } = useSite(siteId)

  const { data: debtorsData, isLoading: isLoadingDebtorPermits } = useAsyncFunction(
    () => getGuestParkingSitePermits({ siteId, idToken }),
    {
      enabled: !!siteId && !!idToken
    }
  )

  const { data: user, isLoading: isLoadingUser } = useAsyncFunction(() =>
    getUserByPhoneNumber(auth.currentUser?.phoneNumber)
  )

  useEffect(() => {
    state.firebaseUser?.getIdToken().then(setIdToken)
  }, [state, state.firebaseUser])

  useEffect(() => {
    const numberOfPermits =
      debtorsData?.debtors?.reduce((acc, debtor) => {
        return acc + debtor.permits.length
      }, 0) || 0
    setNumberOfGuestPermits(numberOfPermits)
    if (numberOfPermits === 1) {
      navigate(`/site/${siteId}/permit/${debtorsData?.debtors[0]?.permits[0].id}`)
    }
  }, [debtorsData])

  const isLoading = isLoadingDebtorPermits || isLoadingSite || isLoadingUser

  const isStaffMember = debtorsData?.debtors?.some((debtor) =>
    debtor.permits.some((permit) => user?.userId && permit.staffMembersIds?.indexOf(user.userId) !== -1)
  )

  const isSiteAdmin =
    user?.role && [HikerUserRole.ROOT, HikerUserRole.ADMIN].includes(user.role) && user?.siteIds?.includes(siteId)

  if (!isLoading && !(isStaffMember || isSiteAdmin)) return <GuestParkingNotAuthorizedScreen />

  return (
    <Box fullWidth fullHeight align="center" top spacing={Spacings.xxxl}>
      <GuestParkingSiteLogo site={site} />
      <Box top bottom spacing={Spacings.xxxl}>
        {isLoading && <Loader size={Spacings.m} />}
        {!isLoading && numberOfGuestPermits === 0 && (
          <FigmaText textKey={HardCodedTexts.textGuestParkingNoGuestPermitsForSite} />
        )}
        {!isLoading &&
          debtorsData?.debtors?.map((debtor) =>
            debtor.permits.map((permit) => (
              <Button sx={{ mb: Spacings.xxs }} fullWidth key={permit.id} href={`/site/${siteId}/permit/${permit.id}`}>
                {debtor.debtorName} - {permit.name}
              </Button>
            ))
          )}
      </Box>
      <Box fullWidth align="center" top spacing={Spacings.xxxl}>
        <Box onClick={logout} link>
          <FigmaText textKey={Texts.textMenuUserMenuUserMenuLogout} />
        </Box>
      </Box>
    </Box>
  )
}
