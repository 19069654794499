import Box from '@guest-parking/components/Box'
import Button from '@guest-parking/components/Button'
import FigmaImage from '@guest-parking/components/FigmaImage'
import FigmaText from '@guest-parking/components/FigmaText'
import { Spacings } from '@guest-parking/enums/Spacings'
import { useLogout } from '@guest-parking/hooks/useLogout'
import Images from '@guest-parking/libs/Images'
import Texts from '@guest-parking/libs/Texts'

export const GuestParkingInvalidTokenScreen: React.FC<{ enableLogoutButton?: boolean }> = ({
  enableLogoutButton = false
}) => {
  const logout = useLogout()

  return (
    <Box fullWidth fullHeight align="center" justify="center">
      <Box top spacing={Spacings.xxxl}>
        <FigmaImage imageKey={Images.drifterLogoDark} />
      </Box>
      <Box top spacing={Spacings.xxxl}>
        <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingInvalidToken} />
      </Box>
      <Box top>
        <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingInvalidTokenContactSupport} />
      </Box>
      {enableLogoutButton && (
        <Box top>
          <Button
            fullWidth
            onClick={logout}
            textKey={Texts.textMenuUserMenuUserMenuLogout}
            verticalSpacing={Spacings.s}
            buttonType="secondary"
          />
        </Box>
      )}
    </Box>
  )
}
