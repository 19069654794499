/// <reference types="vite-plugin-svgr/client" />
import headerAppStoreImg from '@pay/assets/images/headerAppStore.svg?react'
export const headerAppStore = headerAppStoreImg
import pngGooglePlayImg from '@pay/assets/images/pngGooglePlay.png'
export const pngGooglePlay = pngGooglePlayImg
import headerArrowImg from '@pay/assets/images/headerArrow.svg?react'
export const headerArrow = headerArrowImg
import headerLogoAndTextImg from '@pay/assets/images/headerLogoAndText.svg?react'
export const headerLogoAndText = headerLogoAndTextImg
import parkingPImg from '@pay/assets/images/parkingP.png'
export const parkingP = parkingPImg
import homeLandingCtaCreditCardImg from '@pay/assets/images/homeLandingCtaCreditCard.svg?react'
export const homeLandingCtaCreditCard = homeLandingCtaCreditCardImg
import homeLandingShowParkingInfoImg from '@pay/assets/images/homeLandingShowParkingInfo.svg?react'
export const homeLandingShowParkingInfo = homeLandingShowParkingInfoImg
import homeLandingAppStoreImg from '@pay/assets/images/homeLandingAppStore.svg?react'
export const homeLandingAppStore = homeLandingAppStoreImg
import alertTriangleRedImg from '@pay/assets/images/alertTriangleRed.svg?react'
export const alertTriangleRed = alertTriangleRedImg
import infoIconImg from '@pay/assets/images/infoIcon.svg?react'
export const infoIcon = infoIconImg
import closeRoundedImg from '@pay/assets/images/closeRounded.svg?react'
export const closeRounded = closeRoundedImg
import parkingPurpleImg from '@pay/assets/images/parkingPurple.svg?react'
export const parkingPurple = parkingPurpleImg
import stopwatchPurpleImg from '@pay/assets/images/stopwatchPurple.svg?react'
export const stopwatchPurple = stopwatchPurpleImg
import parkIconImg from '@pay/assets/images/parkIcon.svg?react'
export const parkIcon = parkIconImg
import clooseRoundRedImg from '@pay/assets/images/clooseRoundRed.svg?react'
export const clooseRoundRed = clooseRoundRedImg
import thumbsUpImg from '@pay/assets/images/thumbsUp.svg?react'
export const thumbsUp = thumbsUpImg
import closeIconOutlinedImg from '@pay/assets/images/closeIconOutlined.svg?react'
export const closeIconOutlined = closeIconOutlinedImg
import checkIconOutlinedImg from '@pay/assets/images/checkIconOutlined.svg?react'
export const checkIconOutlined = checkIconOutlinedImg
import chargingTimesIconImg from '@pay/assets/images/chargingTimesIcon.svg?react'
export const chargingTimesIcon = chargingTimesIconImg
import chargingTypeIconImg from '@pay/assets/images/chargingTypeIcon.svg?react'
export const chargingTypeIcon = chargingTypeIconImg
import batteryCapacityIconImg from '@pay/assets/images/batteryCapacityIcon.svg?react'
export const batteryCapacityIcon = batteryCapacityIconImg
import registeredAtIconImg from '@pay/assets/images/registeredAtIcon.svg?react'
export const registeredAtIcon = registeredAtIconImg
import infoIconPurpleImg from '@pay/assets/images/infoIconPurple.svg?react'
export const infoIconPurple = infoIconPurpleImg
import activeSessionParkImg from '@pay/assets/images/activeSessionPark.svg?react'
export const activeSessionPark = activeSessionParkImg
import activeSessionParkAndChargeImg from '@pay/assets/images/activeSessionParkAndCharge.svg?react'
export const activeSessionParkAndCharge = activeSessionParkAndChargeImg
import timeIconImg from '@pay/assets/images/timeIcon.svg?react'
export const timeIcon = timeIconImg
import costIconImg from '@pay/assets/images/costIcon.svg?react'
export const costIcon = costIconImg
import chargingIconImg from '@pay/assets/images/chargingIcon.svg?react'
export const chargingIcon = chargingIconImg
import arrowLeftGreyImg from '@pay/assets/images/arrowLeftGrey.svg?react'
export const arrowLeftGrey = arrowLeftGreyImg
import swishLogoWhiteTextImg from '@pay/assets/images/swishLogoWhiteText.svg?react'
export const swishLogoWhiteText = swishLogoWhiteTextImg
import successIconImg from '@pay/assets/images/successIcon.svg?react'
export const successIcon = successIconImg
import shareIconImg from '@pay/assets/images/shareIcon.svg?react'
export const shareIcon = shareIconImg
import mailIconImg from '@pay/assets/images/mailIcon.svg?react'
export const mailIcon = mailIconImg
import receiptDrifterLogoImg from '@pay/assets/images/receiptDrifterLogo.svg?react'
export const receiptDrifterLogo = receiptDrifterLogoImg
import supportChatBubblesImg from '@pay/assets/images/supportChatBubbles.svg?react'
export const supportChatBubbles = supportChatBubblesImg
import informationIconDarkImg from '@pay/assets/images/informationIconDark.svg?react'
export const informationIconDark = informationIconDarkImg
