import styled from '@emotion/styled'
import { InfoOutlined } from '@mui/icons-material'
import { InputAdornment, TextFieldProps } from '@mui/material'
import MuiBox from '@mui/material/Box'
import React from 'react'
import { ControllerFieldState } from 'react-hook-form'

import { FontSize } from '@contracts/enums/Typography'

import { Colors } from '@pure/assets/Colors'
import { TextKey } from '@pure/emu/Antiloop'

import { Spacings } from '@guest-parking/enums/Spacings'
import { getFigmaText } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'

import Box from './Box'
import FigmaText from './FigmaText'
import { HomeErrorMessage } from './HomeErrorMessage'
import { TextFieldOutlined } from './TextFieldOutlined'

export type ErrorComponentProps = { name: string; fieldState?: ControllerFieldState }

export type TextFieldFormProps = TextFieldProps & {
  name: string
  id?: string
  component?: React.ReactNode
  labelTextKey?: TextKey
  labelText?: string
  placeholderTextKey?: TextKey
  adormentTextKey?: TextKey
  isMobile?: boolean
  ErrorComponent?: React.FC<any>
  isWhite?: boolean
  autoFocus?: boolean
  rows?: number
  InputProps?: any
  fullWidth?: boolean
  fieldState: ControllerFieldState
  helperText?: string
}

function TextFieldFormComponent(
  {
    name,
    id,
    fullWidth,
    placeholderTextKey,
    adormentTextKey,
    onChange,
    value,
    onBlur,
    className,
    defaultValue,
    children,
    select,
    type,
    onFocus,
    disabled,
    placeholder,
    onKeyDown,
    multiline,
    rows,
    autoFocus = false,
    InputProps,
    fieldState,
    helperText
  }: TextFieldFormProps,
  ref
) {
  return (
    <StyledWrapper fullWidth={fullWidth}>
      <TextFieldOutlined
        select={select}
        size="small"
        fullWidth={fullWidth}
        value={value}
        className={className}
        disabled={disabled}
        placeholder={placeholder || (!!placeholderTextKey && !value && getFigmaText(placeholderTextKey)) || ''}
        InputProps={
          InputProps || {
            endAdornment: !!adormentTextKey && (
              <InputAdornment position="end">
                <FigmaText textKey={adormentTextKey} />
              </InputAdornment>
            )
          }
        }
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        name={name}
        id={id}
        type={type}
        onKeyDown={onKeyDown}
        multiline={multiline}
        onFocus={onFocus}
        autoFocus={autoFocus}
        rows={rows}
      >
        {children}
      </TextFieldOutlined>
      {!fieldState?.error && helperText && (
        <MuiBox sx={{ display: 'flex', gap: 1, mt: 1 }}>
          <InfoOutlined sx={{ color: Colors.gray500, fontSize: '16px' }} />
          <MuiBox sx={{ fontFamily: 'Inter', fontSize: FontSize.xs, lineHeight: '16px', color: Colors.gray500 }}>
            {helperText}
          </MuiBox>
        </MuiBox>
      )}

      {!!fieldState?.error && (
        <Box top spacing={Spacings.xs}>
          <HomeErrorMessage
            textKey={Texts.textPaymentsPaymentSwishNumberErrorNotConnected}
            text={fieldState?.error.message || ''}
          />
        </Box>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Box)<{ fullWidth?: boolean }>`
  ${(props) => props.fullWidth && 'width: 100%;'}
`

export default React.forwardRef(TextFieldFormComponent)

export function getError(formProps: any, name: any) {
  return !!formProps.errors
}
