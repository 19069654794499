import { en, LANGUAGES } from '@consts/Locale'

import { Language } from '@pure/emu/Antiloop'

export const getLanguageCode = (): Language => getDefaultLanguageCode()

export function getDefaultLanguageCode(): Language {
  const defaultLanguageCode = navigator.language?.substr(0, 2) as Language | undefined
  if (!defaultLanguageCode) return en
  if (LANGUAGES.includes(defaultLanguageCode)) return defaultLanguageCode
  return en
}

export const useLanguage = getLanguageCode
