import styled from '@emotion/styled'
import { ButtonProps as MaterialButtonProps } from '@mui/material/Button'

import { TextKey } from '@pure/emu/Antiloop'

import { Spacings } from '@guest-parking/enums/Spacings'
import { getFigmaText } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'

import Box from './Box'
import ButtonBase from './ButtonBase'
import FigmaTextLoader from './FigmaTextLoader'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export type ButtonProps = {
  className?: string
  spacing?: string
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'empty_state'
  textKey?: TextKey
  loading?: boolean
  text?: string
  color?: string
  verticalSpacing?: string
} & MaterialButtonProps

export default function ButtonPrimary(props: ButtonProps) {
  const {
    children,
    className,
    text,
    textKey = Texts.textButtonsPaymentSelectionButtonText,
    loading,
    verticalSpacing,
    ...buttonProps
  } = props

  return (
    <StyledButton
      className={className}
      variant="contained"
      verticalSpacing={verticalSpacing}
      color="primary"
      spacing={Spacings.m}
      {...buttonProps}
    >
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader
            type="body1Bold"
            textKey={textKey}
            text={text || (textKey ? getFigmaText(textKey) : undefined)}
            loading={loading}
            color="white"
            Component={FigmaTextWithStyle}
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<{
  spacing?: string
  verticalSpacing?: string
}>``
