import styled from '@emotion/styled'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'

import { Colors } from '@pure/assets/Colors'

import { Spacings } from '@guest-parking/enums/Spacings'
import Texts from '@guest-parking/libs/Texts'

import Box from './Box'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export function GuestParkingFormInfoBox() {
  return (
    <InformationBox fullPadding spacing={Spacings.s}>
      <Box bottom>
        <FigmaText textKey={Texts.textActiveSessionGuestParkingUserGuideHeader} />
      </Box>
      <Box bottom spacing={Spacings.s} direction="row" justify="space-between" align="center">
        <Number value="1" />
        <Box left spacing={Spacings.s}>
          <FigmaText textKey={Texts.textActiveSessionGuestParkingUserGuideStep1} />
        </Box>
      </Box>
      <Box bottom spacing={Spacings.s} direction="row" justify="space-between" align="center">
        <Number value="2" />
        <Box left spacing={Spacings.s}>
          <FigmaText textKey={Texts.textActiveSessionGuestParkingUserGuideStep2} />
        </Box>
      </Box>
      <Box spacing={Spacings.s} direction="row" justify="space-between" align="center">
        <Number value="3" />
        <Box left spacing={Spacings.s}>
          <FigmaText textKey={Texts.textActiveSessionGuestParkingUserGuideStep3} />
        </Box>
      </Box>
    </InformationBox>
  )
}

const Number = ({ value }: { value: string }) => {
  return (
    <NumberContainer spacing={Spacings.s} fullPadding align="center" justify="center">
      <FigmaTextWithStyle type="body2Bold" color="white" text={value} />
    </NumberContainer>
  )
}

const InformationBox = styled(Box)`
  border: 1px solid grey;
  border-radius: ${BorderRadiusesPx.lightlyRounded};
`

const NumberContainer = styled(Box)`
  background-color: ${Colors.primary};
  width: 24px;
  height: 24px;
  border-radius: 50%;
`
