// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

enum ZIndices {
  top = 100,
  heaven = 3000,
  modal = 2000,
  focus = 10,
  higher = 2,
  high = 1,
  regular = 0
}

export default ZIndices
