import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'

import { FIREBASE_CONFIG, REGION } from '@consts/HikerConsts'

import { getFirestore } from './getFirestore'

const app = initializeApp(FIREBASE_CONFIG)

const functions = getFunctions(app, REGION)

const auth = getAuth(app)

const db = getFirestore(app)

export { app, auth, db, functions }
