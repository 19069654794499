import QRCode from 'react-qr-code'
import { useParams } from 'react-router-dom'

import { getValueForSiteNumberOfGuests } from '@pure/libs/GuestParkingHelper'

import { env } from '@guest-parking/Config'
import { Spacings } from '@guest-parking/enums/Spacings'
import useAppState from '@guest-parking/hooks/useAppState'
import { useAsyncFunction } from '@guest-parking/hooks/useAsyncFunction'
import { useLogout } from '@guest-parking/hooks/useLogout'
import { getLinkToGuestParking, getLinkToGuestParkingFeauture } from '@guest-parking/libs/CloudFunctionsApiHandler'
import { getUserByPhoneNumber } from '@guest-parking/libs/DBApiHandler'
import { auth } from '@guest-parking/libs/FirebaseOptions'
import { useSite } from '@guest-parking/libs/ReactQueryHooks'
import Texts from '@guest-parking/libs/Texts'
import { GuestParkingNotAuthorizedScreen } from '@guest-parking/screens/GuestParkingNotAuthorizedScreen'

import Box from './Box'
import FigmaText from './FigmaText'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import Loader from './Loader'
const isLocal = env === 'local'

const getDevQRCodeUrl = (qrCodeUrl: string) => {
  const devQRCodeUrl = new URL(qrCodeUrl)

  devQRCodeUrl.hostname = window.location.hostname
  devQRCodeUrl.port = window.location.port
  devQRCodeUrl.protocol = 'http'

  return devQRCodeUrl.toString()
}

export const GuestParkingQRCode = () => {
  const logout = useLogout()
  const { siteId = '0', permitId = '' } = useParams<string>()
  const { state } = useAppState()
  const { data: site, isLoading: isLoadingSite } = useSite(siteId)

  const {
    data,
    isLoading: isLoadingLink,
    error: linkError
  } = useAsyncFunction(
    async () => {
      const idToken = (await state.firebaseUser?.getIdToken()) || ''
      return getLinkToGuestParking({ siteId, permitId, idToken })
    },
    {
      enabled: !!siteId && !!permitId
    }
  )

  // @TODO: This is a multiple versions support
  const { data: legacyData, isLoading: isLoadingLegacyLink } = useAsyncFunction(
    () => getLinkToGuestParkingFeauture({ siteId }),
    {
      enabled: !!siteId && !permitId
    }
  )

  const { data: user, isLoading: isLoadingUser } = useAsyncFunction(() =>
    getUserByPhoneNumber(auth.currentUser?.phoneNumber)
  )

  // @TODO: This is a multiple versions support
  const isLoading = !(isLoadingLink || isLoadingLegacyLink) || isLoadingSite || isLoadingUser

  const qrCodeUrl = data?.url || legacyData?.url
  const n = getValueForSiteNumberOfGuests(site)

  // @TODO: This is a multiple versions support
  const isStaffUser = site?.staffContactDetails?.some((s) => s.phoneNumber === auth.currentUser?.phoneNumber)
  const userHasAccess = user?.siteIds?.includes(siteId)
  const hasAccessLegacy = isStaffUser || userHasAccess
  const hasAccess = hasAccessLegacy || !linkError

  if (!isLoading && !hasAccess) return <GuestParkingNotAuthorizedScreen />

  return (
    <Box fullWidth fullHeight align="center" top spacing={Spacings.xxxl}>
      <GuestParkingSiteLogo site={site} />
      <Box top bottom spacing={Spacings.xxxl}>
        {isLoading && <Loader size={Spacings.m} />}
        {!isLoading &&
          qrCodeUrl &&
          (isLocal ? (
            <a href={getDevQRCodeUrl(qrCodeUrl)}>
              <QRCode value={qrCodeUrl} size={256} />
            </a>
          ) : (
            <QRCode value={qrCodeUrl} size={256} />
          ))}
      </Box>
      <FigmaText textKey={Texts.guestSettingsGuestNumberOfGuests} />
      <FigmaText textKey={Texts.textActiveSessionGuestParkingQrPageNumberOfUsers} text={`${n}`} />
      <Box fullWidth align="center" top spacing={Spacings.xxxl}>
        <Box onClick={logout} link>
          <FigmaText textKey={Texts.textMenuUserMenuUserMenuLogout} />
        </Box>
      </Box>
    </Box>
  )
}
