import { FirebaseApp } from 'firebase/app'
import { Firestore, initializeFirestore } from 'firebase/firestore'

let db: Firestore | undefined = undefined

export function getFirestore(app: FirebaseApp): Firestore {
  if (db) return db
  db = initializeFirestore(app, { ignoreUndefinedProperties: true })
  return db
}
