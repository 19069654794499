import dayjs from 'dayjs'

import { getFigmaText } from '@pure/libs/TextRepository'
import { formatText } from '@pure/libs/TextRepositoryHelper'

import { XXX } from '@web-js/libs/Consts'

import Texts from '@roamer/libs/Texts'

export function getUntilText(finishDate: string) {
  const text = getFigmaText(Texts.textActiveSessionActiveSessionActiveSessionFreeParkingEndTime).replace('HH:mm', XXX)
  const untilText = formatText(text, [dayjs(finishDate).format('HH:mm')])
  return untilText
}
