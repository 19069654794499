enum RoutePath {
  HOME = '/',
  HOME_STATION_ID = '/:parameter',
  SANDBOX = '/sandbox',
  RECEIPT = '/receipt',
  RECEIPT_DETAIL = '/receipt-detail',
  PAYMENT_CALLBACK = '/payment-callback',
  RECEIPT_SEND_SMS = '/receipt-send-sms',
  RECEIPT_SEND_EMAIL = '/receipt-send-email',
  FORTNOX_OAUTH = '/fortnox-oauth',
  FORTNOX_OAUTH_REDIRECT = '/fortnox-oauth-redirect',
  SIGN_UP = '/sign_up',
  SIGN_IN_ROAMER = '/sign_in_roamer',

  // Guest Parking and roamer
  ON_NEW_GUEST_PARKING = '/onNewGuestParking',
  SIGN_IN_GUEST_PARKING = '/sign-in',
  NEW_GUEST = '/new-guest'
}

export default RoutePath
