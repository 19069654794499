import { ConfirmationResult } from 'firebase/auth'
import { RefObject } from 'react'

export enum PreferredLoginMethod {
  MAIL = 'mail',
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  NONE = 'none'
}

export type SignInState = {
  data: {
    phone?: string
    confirmationResult?: ConfirmationResult
    code?: string
  }
  step: SignInSteps
}

export type SignInViewProps = {
  progressRef?: RefObject<any>
  isLoading: boolean
  signInState: SignInState
  onPressContinue: (state: SignInState) => Promise<unknown>
  onPressResendCode: (state: SignInState) => Promise<unknown>
  onPressSignup: () => unknown
  onPressBack: () => unknown
}

export enum SignInSteps {
  LANDING = 'LANDING',
  SIGN_UP = 'SIGN_UP',
  ENTER_CODE = 'ENTER_CODE',
  CREATE_PROFILE = 'CREATE_PROFILE',
  CONFIRM_MAIL = 'CONFIRM_MAIL',
  SELECT_ADDRESS = 'SELECT_ADDRESS'
}
