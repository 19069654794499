import styled from '@emotion/styled'
import { PropsWithChildren } from 'react'

import { Spacings } from '@contracts/enums/Spacings'

import Box, { BoxProps } from './Box'

interface SessionInformationContainerProps extends BoxProps {
  isOffense?: boolean
}

const Container = styled(Box)<{ isOffense?: boolean }>`
  border-radius: 0.625rem;
  border: ${({ isOffense }) =>
    isOffense ? `2px solid var(--color-brand-main, #ff0000)` : `2px solid var(--color-brand-main, #6D63F1)`};
  background: #fff;
  box-shadow: var(--shadow-lg);
  padding: ${Spacings.s};
`

export const SessionInformationContainer = (props: PropsWithChildren<SessionInformationContainerProps>) => (
  <Container {...props} />
)
