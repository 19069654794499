import { FIGMA_MODES_LANG } from '@consts/FigmaConsts'
import { sv } from '@consts/Locale'

import { Variable } from '@contracts/types/Variable'

import { Language, TextKey } from '@pure/emu/Antiloop'
import { getLanguageCode } from '@pure/libs/hooks/useLanguage'
// trigger

export const getFigmaText = (textKey: TextKey, language: Language = getLanguageCode()): string => {
  switch (language) {
    case sv:
      return textKey.texts?.swe || ''
    default:
      return textKey.texts?.eng || ''
  }
}

export const getFigmaTextFromVariable = ({ values }: Variable, language: Language = getLanguageCode()): string =>
  values.find(({ mode }) => mode === FIGMA_MODES_LANG[language])?.value || ''
