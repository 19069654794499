import { signOut } from 'firebase/auth'

import { auth } from '@guest-parking/libs/FirebaseOptions'

import useAppState, { DEFAULT_STATE } from './useAppState'

export function useLogout(onLogout?: () => void) {
  const { setState } = useAppState()

  return () =>
    signOut(auth).finally(() => {
      onLogout && onLogout()
      setState(DEFAULT_STATE)
    })
}
