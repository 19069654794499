import 'whatwg-fetch'

import { Fetcher } from 'openapi-typescript-fetch'

import { paths } from '@pure/swagger/DrifterTypes'

import { ApiHandlerFetchClientLogger } from './ApiHandlerFetchClientLogger'

// Declare fetcher for paths.
export const publicFetcher = Fetcher.for<paths>()

const baseUrl = 'https://app.drifterworld.se'

const fetcherConfig = {
  baseUrl,
  init: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      locale: 'en', // This was previously calling a function where 'en' was hardcoded. Hard-code it explicitly instead of hiding it with indirection.
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  },
  use: [ApiHandlerFetchClientLogger]
}

publicFetcher.configure(fetcherConfig)

// This Fetcher is only used to access LBE in roamer, remove it when removing LBE dependenies
// TODO: DPM-1485
export const authenticatedFetcher = async (auth, osName: string, appVersion: string) => {
  const idToken = await auth?.currentUser?.getIdToken(true)

  const fetcher = Fetcher.for<paths>()
  fetcher.configure({
    ...fetcherConfig,
    init: {
      ...fetcherConfig.init,
      headers: {
        ...fetcherConfig.init.headers,
        Authorization: `Bearer ${idToken}`,
        DrifterAppPlatform: osName,
        DrifterAppVersion: appVersion
      }
    }
  })

  return fetcher
}
