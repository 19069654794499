import type { SlackWebhooks } from '@contracts/enums/SlackWebhooks'

import { Log } from '@pure/libs/Log'

import { config } from './Config'

// @flow
const method = 'POST'

export const slack = async (text: string, url: SlackWebhooks): Promise<unknown> => {
  if (!config?.enableSlack) return Promise.resolve(Log.info(text))
  await fetch(url, { method, body: JSON.stringify({ text }) }).catch(() => ({}))
}

export const formatLink = (url: string, text = 'Link') => `<${url}|${text}>`
