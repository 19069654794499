import { Navigate, useLocation } from 'react-router-dom'

import RoutePath from '@contracts/enums/RoutePath'

import useAppState from '@guest-parking/hooks/useAppState'

export const ProtectedRoute = ({ children }) => {
  const { state } = useAppState()
  const prevLocation = useLocation()

  if (!state.firebaseUser) {
    return (
      <Navigate to={`${RoutePath.SIGN_IN_GUEST_PARKING}?redirectTo=${prevLocation.pathname}${prevLocation.search}`} />
    )
  }

  return children
}

export default ProtectedRoute
