import { useEffect, useRef } from 'react'

export function useInterval(callback: (...args: any[]) => any, delay?: number | null) {
  const savedCallback = useRef<typeof callback>(() => {})

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    if (delay !== null) {
      const interval = setInterval(() => savedCallback.current(), delay || 0)
      return () => clearInterval(interval)
    }

    return
  }, [delay])
}
