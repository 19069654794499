// @flow
import { createTheme } from '@mui/material'

import { Colors } from '@pure/assets/Colors'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

export const DefaultMaterialTheme = {
  palette: {
    primary: createColor(Colors.primary),
    secondary: createColor(Colors.secondary)
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#000'
      }
    }
  }
}

export const MaterialTheme = createTheme(DefaultMaterialTheme)
