const BorderRadiusesPx = {
  minimum: '4px',
  lightlyRounded: '8px',
  rounded: '16px',
  full: '360px'
}

const BorderRadiuses = {
  minimum: 4,
  lightlyRounded: 8,
  rounded: 16,
  full: 360
}

export { BorderRadiuses, BorderRadiusesPx }
