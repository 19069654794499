export const sv = 'sv'
export const en = 'en'
export const LANGUAGES = [sv, en]
export const LANGUAGES_OBJECT = { sv: 'Svenska', en: 'English' }
export const LANGUAGES_OPTIONS = Object.keys(LANGUAGES_OBJECT).map((code) => ({
  key: LANGUAGES_OBJECT[code],
  value: code
}))

export const EUROPE_WEST_1 = 'europe-west1'
export const EUROPE_WEST_3 = 'europe-west3'
export const US_CENTRAL_1 = 'us-central1'
