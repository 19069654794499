import { GetParkingSessionOffenseEventData } from '@contracts/types/GetParkingSessionData'
import { ParkingSessionEventOffense } from '@contracts/types/ParkingSession'
import { SiteFeatures, SiteOffenseFees } from '@contracts/types/SiteFeatures'

export enum OffenseType {
  disabled_slot = 'disabled_slot',
  marked_slot = 'marked_slot',
  vehicle_position = 'vehicle_position',
  quickstop_overstay = 'quickstop_overstay',
  charge_slot_offense = 'charge_slot_offense'
}
export type OffenseTypeItem = {
  featureKey: keyof SiteFeatures
  maxAmountKey: keyof SiteOffenseFees
  getPartialEvent?: (opts: GetParkingSessionOffenseEventData) => Partial<ParkingSessionEventOffense> | undefined
}
