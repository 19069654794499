export const LogSeverity = {
  ERROR: 'ERROR',
  WARN: 'WARN',
  INFO: 'INFO',
  DEBUG: 'DEBUG',
  TRACE: 'TRACE'
} as const

export type LogSeverity = (typeof LogSeverity)[keyof typeof LogSeverity]

export class Log {
  static error(message: string): void {
    this.logMessage(message, LogSeverity.ERROR)
  }

  static warn(message: string): void {
    this.logMessage(message, LogSeverity.WARN)
  }

  static debug(message: string): void {
    this.logMessage(message, LogSeverity.DEBUG)
  }

  static trace(message: string): void {
    this.logMessage(message, LogSeverity.TRACE)
  }

  static info(message: string): void {
    this.logMessage(message, LogSeverity.INFO)
  }

  private static logMessage(message: string, severity: LogSeverity): void {
    switch (severity) {
      case LogSeverity.ERROR:
        console.error(message)
        break
      case LogSeverity.WARN:
        console.warn(message)
        break

      case LogSeverity.DEBUG:
        console.debug(message)
        break
      case LogSeverity.TRACE:
        console.trace(message)
        break
      default:
      case LogSeverity.INFO:
        console.info(message)
        break
    }
  }
}
