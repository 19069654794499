import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'

import Collections, { CollectionGroups } from '@contracts/enums/Collections'
import { GuestParkingSession } from '@contracts/types/GuestParkingSession'
import { HikerUser } from '@contracts/types/HikerUser'

import { createUniqueId } from '@pure/libs/Common'
import { getDocData } from '@pure/libs/CommonDBApiHandlerHelper'

import { db } from './FirebaseOptions'

export const upsertGuestParkingSession = async (p: GuestParkingSession): Promise<any> =>
  setDoc(
    doc(
      db,
      `${Collections.SITES}/${p.siteId}/${CollectionGroups.GUEST_PARKING_SESSIONS}`,
      p.session?.id?.toString() || createUniqueId()
    ),
    p
  ).then(() => p)

export const getUserByPhoneNumber = async (phoneNumber?: string | null) => {
  const result = await getDocs(query(collection(db, Collections.USERS), where('phone', '==', phoneNumber)))
  return (await getDocData(result.docs[0])) as HikerUser
}
