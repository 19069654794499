// @flow
import dayjs from 'dayjs'
import invariant from 'invariant'

import { ONE_SECOND } from '@consts/DateConsts'

type QueryParams = { [key: string]: string }

export const groupBy = <T>(xs: Array<T>, key: keyof T): { [key: string]: Array<T> } => {
  return xs.reduce(
    (rv, x: any) => {
      if (!x[key]) return rv
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    },
    {} as { [key: string]: Array<T> }
  )
}

export const createUniqueId = () => Math.random().toString(36).substr(2, 9)

export const getArray = (length: number): Array<number> =>
  // eslint-disable-next-line prefer-spread
  Array.apply(null, { length } as any).map(Number.call, Number) as number[]

export const asObject = <T>(arr: Array<T>, key: keyof T): { [key: string]: T } =>
  arr.reduce(
    (a, b) => {
      if (!b[key]) return a

      //@ts-ignore
      a[b[key]] = b
      return a
    },
    {} as { [key: string]: T }
  )

export const removeDuplicates = <T>(arr: Array<T>, key: keyof T): T[] => asArray(asObject(arr, key))

export const asArray = <T>(obj: { [key: string]: T }): T[] => Object.keys(obj).map((_id) => obj[_id])

export const isNumber = (n: unknown) => typeof n === 'number' && !isNaN(n)
export const isString = (n: unknown) => typeof n === 'string'

export const getRandomValue = (floor: number, ceil: number) => {
  const diff = ceil - floor
  return floor + Math.random() * diff
}

export const getMean = (arr: number[]) => {
  if (arr.length === 0) return 0
  return arr.reduce((a, b) => a + b, 0) / arr.length
}

export const formatPercent = (n: number) => `${n.toFixed()}%`

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getDomain = (email: string) => {
  const domain = email.split('@')[1]
  invariant(domain, 'Cant parse email: %s', email)
  return domain.toLowerCase()
}

export const getEmailForFirebaseUser = (user: any): string | undefined => {
  return user.email || user.providerData?.[0]?.email
}

export const mapCssHeightToNumber = (cssHeightInPixels: string) => parseInt(cssHeightInPixels.replace('px', ''))

export function getDecimals(num: number) {
  if (Math.floor(num.valueOf()) === num.valueOf()) return 0
  return num.toString().split('.')[1]?.length || 0
}

export const toPx = (cssString: string) => parseInt(cssString.replace('px', ''))

export function getObjectOrBoolForJSON(str = ''): object | boolean {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}

export const isValidJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getChunks = <T>(array: Array<T>, chunkSize: number): Array<Array<T>> => {
  let i
  let j
  let temparray = [] as T[]
  const res = [] as T[][]
  for (i = 0, j = array.length; i < j; i += chunkSize) {
    temparray = array.slice(i, i + chunkSize)
    res.push(temparray)
  }

  return res
}

export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export const isThisWeek = (date: string) => dayjs(dayjs(date)).isSame(new Date(), 'week')

export function removeUndefinedProperties<T extends object>(obj: T): T {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}

export function convertToCSV(objArray: any[]): string {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
  let str = `${Object.keys(array[0]).join()},\r\n`

  for (let i = 0; i < array.length; i++) {
    let line = ''
    for (const index in array[i]) {
      if (line !== '') line += ','

      line += array[i][index]
    }

    str += line + '\r\n'
  }

  return str
}

export function removeDuplicateStrings(arr: string[] = []): string[] {
  return [...new Set(arr.filter((a) => !!a) || [])]
}

export const runInSequence = (funcs: Array<() => Promise<any>>) => {
  let result = Promise.resolve() as any
  return Promise.all(
    funcs.map((task) => {
      result = result.then(() => task())
      return result
    })
  )
}

export const delay = (ms = ONE_SECOND) => new Promise((res) => setTimeout(res, ms))

export const takeFirst = (_, i) => i === 0

export const takeFirst30 = (x: string, i: number): boolean => i < 30 // https://drifterworldgroup.slack.com/archives/C06CTB3135L/p1717312260062139
